import { createWorkerPool } from '@eturi/util/dom'
import type {
	CryptoWorkerPLType,
	DecryptBufferPL,
	DecryptJsonPL,
	DecryptPemPairPL,
	DecryptTempKeysPL,
	EncryptPrivateKeyPL,
	GeneratePwHashPL,
	PemPair,
	TempKeyMap,
} from './types.js'

export const createCryptoWorkerPool = (poolSize: number) => {
	const createWorker = () =>
		new Worker(new URL('./crypto.worker.ts', import.meta.url), { type: 'module' })

	const cryptoWorkerPool = createWorkerPool(createWorker, poolSize, true)

	function exec(type: 'decrypt_buf', payload: DecryptBufferPL): Promise<ArrayBuffer>
	function exec<R>(type: 'decrypt_json', payload: DecryptJsonPL): Promise<R>
	function exec(type: 'decrypt_pem_pair', payload: DecryptPemPairPL): Promise<PemPair>
	function exec(type: 'decrypt_tmp', payload: DecryptTempKeysPL): Promise<TempKeyMap>
	function exec(type: 'enc_pk', payload: EncryptPrivateKeyPL): Promise<string>
	function exec(type: 'gen_pem_pair'): Promise<PemPair>
	function exec(type: 'gen_pw_hash', payload: GeneratePwHashPL): Promise<string>
	function exec(type: CryptoWorkerPLType, payload?: any): Promise<any> {
		return cryptoWorkerPool.exec({ payload: { ...payload, type } })
	}

	return { ...cryptoWorkerPool, exec }
}
