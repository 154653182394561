import { useFn } from '@eturi/react'
import noop from 'lodash/noop.js'
import type { SyntheticEvent } from 'react'

export type EvtHandler<T extends Event = Event> = (ev: T) => any
export type EvtPredicate<T extends Event = Event> = ((ev: T) => boolean) | boolean
export type SynEvtHandler<T extends SyntheticEvent = SyntheticEvent> = (ev: T) => any
export type SynEvtPredicate<T extends SyntheticEvent = SyntheticEvent> =
	| ((ev: T) => boolean)
	| boolean

/**
 * Contains some hacks to get event handling helpers to work without a massive
 * amount of superfluous code. Defines functions for prevent default / stop prop
 * for both SyntheticEvent and Event handlers, all using the same underlying
 * function.
 */
const _useHandleEvt = (handler: any, prevent: any, stop: any) =>
	useFn((ev: any) => {
		if ((typeof prevent === 'function' && prevent(ev)) || prevent) ev.preventDefault()
		if ((typeof stop === 'function' && stop(ev)) || stop) ev.stopPropagation()
		handler(ev)
	})

/* eslint-disable max-len */
/** @see _useHandleEvt */
export const useHandlePrevent = <T extends Event = Event>(
	handler = noop,
	prevent: EvtPredicate<T> = true,
): EvtHandler<T> => _useHandleEvt(handler, prevent, false)

/** @see _useHandleEvt */
export const useHandleSynPrevent = <T extends SyntheticEvent = SyntheticEvent>(
	handler = noop,
	prevent: SynEvtPredicate<T> = true,
): SynEvtHandler<T> => _useHandleEvt(handler, prevent, false)

/** @see _useHandleEvt */
export const useHandleStop = <T extends Event = Event>(
	handler = noop,
	stop: EvtPredicate<T> = true,
): EvtHandler<T> => _useHandleEvt(handler, false, stop)

/** @see _useHandleEvt */
export const useHandleSynStop = <T extends SyntheticEvent = SyntheticEvent>(
	handler = noop,
	stop: SynEvtPredicate<T> = true,
): SynEvtHandler<T> => _useHandleEvt(handler, false, stop)

/** @see _useHandleEvt */
export const useHandleEvent = <T extends Event = Event>(
	handler = noop,
	prevent: EvtPredicate<T> = true,
	stop: EvtPredicate<T> = true,
): EvtHandler<T> => _useHandleEvt(handler, prevent, stop)

/** @see _useHandleEvt */
export const useHandleSynEvent = <T extends SyntheticEvent = SyntheticEvent>(
	handler = noop,
	prevent: SynEvtPredicate<T> = true,
	stop: SynEvtPredicate<T> = true,
): SynEvtHandler<T> => _useHandleEvt(handler, prevent, stop)

/* eslint-enable */
