import { startOf, subtract } from '@eturi/date-util'
import { pick } from '@eturi/util'
import type { DeviceHistoryMap, GeoDeviceInfo, LocationData, ManagedDevice } from '@op/services'
import {
	activeChildId$,
	activeManagedChild$,
	activeUserValidDevices$,
	canAccessLocator$,
	children$,
	geoInfo$,
	isGeoCapableDevice,
	locationHistory$,
	locationHistoryDate$,
	locationInfo$,
	managedDevices$,
	nonRetiredManagedDevices$,
} from '@op/services'
import { serverISOToClient } from '@op/util'
import { createSelector } from '@reduxjs/toolkit'
import find from 'lodash/find'
import forOwn from 'lodash/forOwn'
import isEmpty from 'lodash/isEmpty'
import reduce from 'lodash/reduce'
import { activeLocationDeviceId$, locatorCurrentISOStr$ } from '../reducers/geo-ui.slice'
import type {
	DecoratedLocationHistory,
	GeofenceNameMap,
	GeofenceRadiusMap,
	GMapsData,
	MapOverlayNoticeData,
} from '../types'
import { PICK_GMAPS_DEVICE, PICK_GMAPS_USER } from '../types'
import { PICK_AVATAR_MARKER_DEVICE } from '../types/AvatarMarkerData'
import { DEDUPE_GEOFENCE_RADIUS, DEDUPE_LOCATION_RADIUS, distanceBetweenLatLng } from '../util'

// TODO: isFetchingSelectedLocationHistory$  - need to implement some sort of local storage

/**
 * Map data is for today if location history date is null or if it matches today TS
 */
export const isMapDataForToday$ = /*@__PURE__*/ createSelector(
	locatorCurrentISOStr$,
	locationHistoryDate$,
	(locatorCurrentISOStr, locationHistoryDate) =>
		Boolean(!locationHistoryDate || locatorCurrentISOStr === locationHistoryDate),
)

// Location history isn't updated after more than 3 days. So we stop polling on
// it if we're more than 3 days in the past
export const shouldPollLocationHistory$ = /*@__PURE__*/ createSelector(
	canAccessLocator$,
	locatorCurrentISOStr$,
	locationHistoryDate$,
	(canAccessLocator, todayDate, selectedHistoryDate) => {
		if (!canAccessLocator) return false
		// If selectedHistoryDate is null then we're polling today
		if (!selectedHistoryDate) return true

		const lastDayToPoll = subtract(serverISOToClient(todayDate), 3, 'd')
		const selectedDate = startOf(serverISOToClient(selectedHistoryDate), 'd')

		return selectedDate > lastDayToPoll
	},
)

const activeChildDeviceInfo$ = /*@__PURE__*/ createSelector(
	activeChildId$,
	locationInfo$,
	(id, loc): Maybe<GeoDeviceInfo> => (id ? loc[id]?.device_info : null),
)

export const activeChildGMapsData$ = /*@__PURE__*/ createSelector(
	activeManagedChild$,
	activeChildDeviceInfo$,
	activeUserValidDevices$,
	(child, deviceInfo, devices) => {
		const gMapsData: GMapsData[] = []

		if (!(child && deviceInfo)) return gMapsData

		const user = pick(child, PICK_GMAPS_USER)

		for (const device_id in deviceInfo) {
			const location = deviceInfo[device_id].location[0]
			let device

			if (
				isEmpty(location) ||
				!((device = find(devices, { device_id })) && isGeoCapableDevice(device))
			) {
				continue
			}

			gMapsData.push({
				device: pick(device, PICK_GMAPS_DEVICE),
				location,
				user,
			})
		}

		return gMapsData
	},
)

// Retrieves the most recent location update data
export const lastActiveChildLocation$ = /*@__PURE__*/ createSelector(
	activeChildGMapsData$,
	(gMapData) =>
		gMapData.reduce(
			(mostRecentLocationData: Maybe<GMapsData>, data) =>
				(mostRecentLocationData?.location.location_ts ?? -1 > data.location.location_ts) ?
					mostRecentLocationData
				:	data,
			null,
		),
)

export const hasRecentActiveChildLocation$ = /*@__PURE__*/ createSelector(
	lastActiveChildLocation$,
	Boolean,
)

// We find the most recent device id for the active child
const mostRecentActiveChildDeviceId$ = /*@__PURE__*/ createSelector(
	activeChildDeviceInfo$,
	(deviceInfo) => {
		if (!deviceInfo) return null

		let mostRecentTs = 0
		let mostRecentId: Maybe<string> = null

		for (const deviceId in deviceInfo) {
			const deviceGeo = deviceInfo[deviceId]

			if (!deviceGeo.device_location_ts) continue

			if (deviceGeo.device_location_ts > mostRecentTs) {
				mostRecentTs = deviceGeo.device_location_ts
				mostRecentId = deviceId
			}
		}

		return mostRecentId
	},
)

const getLastActiveDeviceIdForUser = (activeChildId: string, devices: ManagedDevice[]) => {
	let recentDeviceId: Maybe<string> = null
	let recentActivityTs = 0

	for (const device of devices) {
		if (!device.users.includes(activeChildId)) continue

		const lastActivityTs = device.last_activity || device.last_seen || 0

		if (lastActivityTs > recentActivityTs) {
			recentDeviceId = device.device_id
			recentActivityTs = lastActivityTs
		}
	}

	return recentDeviceId
}

const getMostRecentLocationHistoryDeviceId = (deviceHistoryMap: Maybe<DeviceHistoryMap>) => {
	if (!deviceHistoryMap) return null

	let recentDeviceId: Maybe<string> = null
	let recentActivityTS = 0

	for (const deviceId in deviceHistoryMap) {
		let firstPath

		if (!(firstPath = deviceHistoryMap[deviceId].path[0])) continue

		const lastUpdatedTs = firstPath.location_ts

		if (lastUpdatedTs > recentActivityTS) {
			recentActivityTS = lastUpdatedTs
			recentDeviceId = deviceId
		}
	}

	return recentDeviceId
}

// Returns the deviceId for either the selected device whose history
// we want or it returns the device id that has the most recent location
// updates for the active child
export const selectedLocationDeviceId$ = /*@__PURE__*/ createSelector(
	activeChildId$,
	activeLocationDeviceId$,
	isMapDataForToday$,
	locationHistory$,
	mostRecentActiveChildDeviceId$,
	nonRetiredManagedDevices$,
	(
		activeChildId,
		activeLocationDeviceId,
		isMapDataForToday,
		locationHistory,
		mostRecentActiveChildDeviceId,
		nonRetiredManagedDevices,
	): Maybe<string> => {
		if (!activeChildId) return
		if (activeLocationDeviceId) return activeLocationDeviceId
		if (mostRecentActiveChildDeviceId) return mostRecentActiveChildDeviceId

		// We iterate through devices to figure out which device has the most
		// recent activity
		const mostRecentDeviceIdFromHistory = getMostRecentLocationHistoryDeviceId(
			locationHistory[activeChildId],
		)

		if (mostRecentDeviceIdFromHistory) return mostRecentDeviceIdFromHistory

		// We only go through the devices ids for the current day
		if (!isMapDataForToday) return null

		// If the user doesn't have activity for the day, then we return
		// the last active device they have
		return getLastActiveDeviceIdForUser(activeChildId, nonRetiredManagedDevices)
	},
)

// Creates an array of decorated history data. Each device contains the location history of that device
// NOTE: Use by gMap's Data (active avatar markers) and child dropdown menu
//  we don't really need all this information in objects. At most we need a map
//  of children to devices and their last location info
export const decoratedLocationHistory$ = /*@__PURE__*/ createSelector(
	locationHistory$,
	locationHistoryDate$,
	(locationHistory, date) => {
		const decoratedLocationHistory: DecoratedLocationHistory[] = []

		for (const userId in locationHistory) {
			const userLocationHistory = locationHistory[userId]

			for (const deviceId in userLocationHistory) {
				const { path, points } = userLocationHistory[deviceId]

				decoratedLocationHistory.push({
					date,
					deviceId,
					path,
					points,
					userId,
				})
			}
		}

		return decoratedLocationHistory
	},
)

// Gets the breadcrumbs for the selected user and device, without
// the current user location added
export const selectedLocationHistory$ = /*@__PURE__*/ createSelector(
	activeChildId$,
	decoratedLocationHistory$,
	selectedLocationDeviceId$,
	(
		activeChildId,
		decoratedLocationHistory,
		selectedLocationDeviceId,
	): Maybe<DecoratedLocationHistory> => {
		if (!activeChildId) return null

		const deviceSelector =
			selectedLocationDeviceId ?
				(deviceId: string) => deviceId === selectedLocationDeviceId
			:	() => true

		return decoratedLocationHistory.find(
			(history) => history.userId === activeChildId && deviceSelector(history.deviceId),
		)
	},
)

// Creates a map of radii to use for each geofence, if the geofence less
// than DEDUPE_GEOFENCE_RADIUS then we use that radius but if its greater
// we use our const since we want to show loc progress even within a geofence
export const geofenceDedupeRadiusMap$ = /*@__PURE__*/ createSelector(geoInfo$, (geoInfo) =>
	reduce(
		geoInfo,
		(radiusMap: Writable<GeofenceRadiusMap>, info, id) => {
			radiusMap[id] = Math.min(info.radius, DEDUPE_GEOFENCE_RADIUS)
			return radiusMap
		},
		{},
	),
)

// TODO: Verify that geofence names or addresses must be unique. Otherwise,
//  this map could overwrite members.
export const geofenceNameMap$ = /*@__PURE__*/ createSelector(geoInfo$, (geoInfo) =>
	reduce(
		geoInfo,
		(map: Writable<GeofenceNameMap>, { address, name }, id) => {
			map[id] = name || address
			return map
		},
		{},
	),
)

export const activeUserLocationData$ = /*@__PURE__*/ createSelector(
	activeChildId$,
	locationInfo$,
	selectedLocationDeviceId$,
	(activeChildId, locationInfo, selectedLocationDeviceId): Maybe<LocationData> =>
		locationInfo[activeChildId || '']?.device_info[selectedLocationDeviceId || '']?.location[0],
)

// This selector only focuses on the users lat/lng since activeUserLocationData
// has a timestamp that updates, it might cause downstream selectors to trigger
// even if the lat/lng hasn't change so we grab just those values
export const activeUserLatLng$ = createSelector(
	activeUserLocationData$,
	isMapDataForToday$,
	(locationData, isMapDataForToday) => {
		// Since we only add current location to
		if (!locationData || !locationData.location_data || !isMapDataForToday) return null
		return locationData.location_data
	},
)

export const avatarMarkerData$ = /*@__PURE__*/ createSelector(
	children$,
	decoratedLocationHistory$,
	geofenceDedupeRadiusMap$,
	isMapDataForToday$,
	locationInfo$,
	nonRetiredManagedDevices$,
	(
		children,
		decoratedLocationHistory,
		geofenceDedupeRadiusMap,
		isMapDataForToday,
		locationInfo,
		nonRetiredManagedDevices,
	) => {
		const gMapsData: GMapsData[] = []

		if (!isMapDataForToday) return gMapsData

		forOwn(locationInfo, (userGeo) => {
			forOwn(userGeo.device_info, (deviceGeo, deviceId) => {
				let child
				let device
				let location = deviceGeo.location[0]

				// If they don't have a location OR the child doesn't have a device assigned to them
				// and it is not geo capable then we break
				if (
					isEmpty(location) ||
					!(
						(child = children.find((u) => u.devices?.includes(deviceId))) &&
						(device = nonRetiredManagedDevices.find((d) => d.device_id === deviceId)) &&
						isGeoCapableDevice(device)
					)
				) {
					return
				}

				// If we're on the current day we must check to see if most recent
				// location is being consolidated with location history we check for
				// most current day since we don't want to do extra calcs when looking
				// at old location history
				if (decoratedLocationHistory) {
					const deviceLocationHistory = decoratedLocationHistory.find(
						(h) => h.deviceId === deviceId,
					)
					const lastHistoryPoint = deviceLocationHistory?.points[0]

					if (lastHistoryPoint) {
						const maxThreshold = Math.max(
							geofenceDedupeRadiusMap[lastHistoryPoint.place || ''] || 0,
							DEDUPE_LOCATION_RADIUS,
						)

						// If the current location is being consolidated with the last
						// history point then we want to show the user at that point for a
						// cleaner UI
						if (distanceBetweenLatLng(location.location_data, lastHistoryPoint) < maxThreshold) {
							const { lat, lng, location_ts } = lastHistoryPoint
							location = { ...location, location_data: { lat, lng }, location_ts }
						}
					}
				}

				gMapsData.push({
					device: pick(device, PICK_AVATAR_MARKER_DEVICE),
					location,
					user: pick(child, PICK_GMAPS_USER),
				})
			})
		})

		return gMapsData
	},
)

export const mapOverlayNotice$ = /*@__PURE__*/ createSelector(
	activeChildDeviceInfo$,
	managedDevices$,
	activeChildId$,
	selectedLocationDeviceId$,
	(childDeviceInfo, devices, activeChildId, selectedDeviceId): Maybe<MapOverlayNoticeData> => {
		if (!(activeChildId && selectedDeviceId)) return null

		const device = devices.find((d) => d.device_id === selectedDeviceId)

		// First we check to see if the device is still connected to the account
		// and user
		if (!device) return { type: 'no_longer_connected' }

		if (device.management_removed || !device.users.includes(activeChildId)) {
			return {
				deviceName: device.displayName,
				type: 'unmanaged_devices',
			}
		}

		if (!childDeviceInfo) return

		const deviceGeo = childDeviceInfo[selectedDeviceId]

		if (!deviceGeo) return

		// If we don't have a timestamp, it means that location was never toggled
		// on for this device, and if it's not active or doesn't exist, obviously
		// we don't care
		if (!deviceGeo.device_location_active && deviceGeo.device_location_ts) {
			return {
				deviceName: device.displayName,
				type: 'location_disabled',
			}
		}

		// Add inactive location data
		if (deviceGeo.location[0]?.isInactive) return { type: 'device_inactive' }
	},
)
