import type { BinarySource } from './binary-util.js'
import { b64ToUint8Array, bToB64 } from './binary-util.js'

const PemWrapper = {
	ENCRYPTED: 'ENCRYPTED PRIVATE',
	PRIVATE: 'RSA PRIVATE',
	PUBLIC: 'PUBLIC',
} as const
type PemWrapperType = keyof typeof PemWrapper

const PEM_MAX_LINE_LENGTH = 64
const PEM_WRAPPER_REGEXP = /^-----(?:BEGIN|END) (?:ENCRYPTED |RSA )?(?:PRIVATE|PUBLIC) KEY-----$/

export const pemDecode = (pem: string) => {
	const base64 = pem
		.split(/\n+/)
		.filter((l) => !PEM_WRAPPER_REGEXP.test(l))
		.join('')

	return b64ToUint8Array(base64)
}

export const pemEncode = (src: BinarySource, type: PemWrapperType) => {
	const b64 = bToB64(src)
	const pemArr = [`-----BEGIN ${PemWrapper[type]} KEY-----`]

	for (let i = 0; i < b64.length; ) {
		pemArr.push(b64.slice(i, (i += PEM_MAX_LINE_LENGTH)))
	}

	pemArr.push(`-----END ${PemWrapper[type]} KEY-----`)

	return pemArr.join('\n')
}
