import { duration, now } from '@eturi/date-util'
import { hash, pick, timeoutPromise } from '@eturi/util'
import type { ThunkAction, UnknownAction } from '@reduxjs/toolkit'
import stringify from 'fast-json-stable-stringify'
import isFunction from 'lodash/isFunction.js'
import isObject from 'lodash/isObject.js'
import random from 'lodash/random.js'
import { v4 } from 'uuid'
import { httpUnauthorizedAction } from './actions/index.js'
import {
	APIErrorCode,
	createAPIError,
	createReqError,
	isReqErrorType,
	ReqErrorCode,
} from './types/index.js'

export type HttpReqMethod = 'GET' | 'POST' | 'PUT' | 'DELETE'

// Retry
export type RetryOpts = {
	readonly max?: number
	readonly shouldRetry?: boolean | ((status: number) => boolean)
}

export type HttpForce = boolean | 'soft'

type RequiredRetryOpts = Required<RetryOpts>

type NormalizedRetry = {
	readonly max: number
	readonly shouldRetry: (status: number) => boolean
}

const DEFAULT_RETRY_OPTS = {
	max: 10,
	shouldRetry: (status) =>
		status === 0 /* Network failure */ ||
		status === 408 /* Client request timeout */ ||
		status === 429 /* Too many requests */ ||
		status >= 500 /* Server-related errors */,
} as const satisfies RequiredRetryOpts

const normalizeRetry = (retry: RetryOpts = {}, defaults: Required<RetryOpts>): NormalizedRetry => {
	const shouldRetry = retry.shouldRetry || defaults.shouldRetry

	return {
		max: retry.max ?? defaults.max,
		shouldRetry: isFunction(shouldRetry) ? shouldRetry : () => shouldRetry,
	}
}

type HttpHeaders = {
	readonly header?: Record<string, string>
	readonly isAuthorized: boolean
	readonly requiresAuth: boolean
}

type CreateHeadersThunk<State, Extra> = (
	extra: HttpExtra<State, Extra>,
	url: string,
	method: HttpReqMethod,
	body: string | undefined,
) => ThunkAction<HttpHeaders | Promise<HttpHeaders>, State, any, UnknownAction>

const normalizeHeaders = (headers?: Maybe<Record<string, string>>) => ({
	Accept: 'application/json',
	'Content-Type': 'application/json',
	...headers,
})

const normalizeHttpData = (data?: any): string | undefined =>
	data == null ? undefined
	: isObject(data) ? JSON.stringify(data)
	: data

type NormalizeUrl = (pathOrUrl: string) => string | Promise<string>

export type HttpLifecycleHandler<State, Extra> = (
	extra: HttpExtra<State, Extra>,
	reqId: string,
) => ThunkAction<any, State, any, UnknownAction>

export type HttpErrorHandler<State, Extra> = (
	extra: HttpExtra<State, Extra>,
	error: any,
	reqId?: string,
) => ThunkAction<any, State, any, UnknownAction>

type HttpOpts = {
	readonly normalizeUrl?: NormalizeUrl
	readonly retry?: RetryOpts
}

type RequiredHttpOpts = Required<MOmit<HttpOpts, 'retry'>> & {
	readonly retry: RequiredRetryOpts
}

type HttpRetryHandler<State, Extra> = (
	extra: HttpExtra<State, Extra>,
	reqId: string,
	retries: number,
) => ThunkAction<any, State, any, UnknownAction>

type CreateHttpOpts<State, Extra = Record<string, unknown>> = {
	[P in Lowercase<HttpReqMethod>]?: HttpOpts
} & HttpOpts & {
		readonly headers: CreateHeadersThunk<State, Extra>
		readonly onAfterFetch?: HttpLifecycleHandler<State, Extra>
		readonly onBeforeFetch?: HttpLifecycleHandler<State, Extra>
		readonly onError: HttpErrorHandler<State, Extra>
		readonly onRetry?: HttpRetryHandler<State, Extra>
		readonly onPendingFetch?: HttpLifecycleHandler<State, Extra>
	}

const DEFAULT_HTTP_OPTS = {
	// Identity
	normalizeUrl: (url: string) => url,
	retry: DEFAULT_RETRY_OPTS,
} as const satisfies RequiredHttpOpts

const normalizeOpts = (opts: HttpOpts): RequiredHttpOpts =>
	pick(
		{
			...DEFAULT_HTTP_OPTS,
			...opts,
			retry: normalizeRetry(opts.retry, DEFAULT_HTTP_OPTS.retry),
		},
		['normalizeUrl', 'retry'],
	)

// The time limit for an HTTP request to resolve before it's considered lost.
const REQ_TIMEOUT_MS = duration(45, 's')

// Allow imperfectly typed HttpExtra to accept arbitrary object, but retain
// types on what we've defined (data, force, etc).
type AnyObject = {
	readonly [k: string]: any
}

export type HttpExtra<State = AnyObject, Extra = AnyObject> = {
	readonly cache?: boolean
	readonly data?: any
	readonly force?: HttpForce
	readonly isUnauthenticated?: boolean
	readonly onError?: CreateHttpOpts<State, Extra>['onError']
	readonly retry?: HttpOpts['retry']
} & Extra

type HttpMethod<State, Extra> = <R>(
	pathOrUrl: string,
	extra?: HttpExtra<State, Extra>,
) => ThunkAction<Promise<R>, State, any, UnknownAction>

export type OPHttp<State, Extra> = {
	[P in Lowercase<HttpReqMethod>]: HttpMethod<State, Extra>
}

/**
 * This is meant to be created and passed as the `extraArgument` to the
 * 'redux-thunk`, when creating a store. This makes it available in async
 * thunks via `createAsyncThunk`, and allows us to define async thunks in
 * shared code that still works in environments w/ different instances of
 * this http object.
 *
 * This creates an `http` object w/ all the methods on it that is bound to
 * state and extra that can be passed. It was created to allow us to share the
 * fundamental logic between modules that have differences in the way they
 * handle things like authentication, error handling, normalizing urls, etc.
 *
 * The options require passing a way of getting an `HttpAuth` state as a thunk
 * dispatch, as well as a default error handler. Additional default options can
 * be passed for lifecycle thunks (`onBeforeFetch`, `onAfterFetch`), as well
 * as url normalization, whether to use cache by default, and default retry
 * options.
 * @see CreateHttpOpts
 * @see HttpOpts
 *
 * Additionally, the default "extra" options need to be passed. These are the
 * set of options that can be passed as a second argument to any http call.
 * These include whether to `force` a call (ignore caching), and `data` to
 * be included in a POST body, etc.
 * @see HttpExtra
 *
 * The primary purpose of encoding State and Extra is to allow types to flow
 * through any of the thunks that can be called. E.g. `onError`,
 * `onAfterFetch`, etc.
 */
export const createHttp = <State, Extra>(
	opts: CreateHttpOpts<State, Extra>,
	defaultExtra: HttpExtra<State, Extra>,
): OPHttp<State, Extra> => {
	const { headers: getHttpHeaders, onAfterFetch, onBeforeFetch, onPendingFetch, onRetry } = opts

	const activeArgsCache = new Map()
	const retryState = new Map<string, number>()
	// NOTE: Currently this compares debounce cache, but if a request is being
	//  retried, it'll be cleared from cache. This was true before the periodic
	//  cache pruning as well when `cache.get()` was called. If we want to keep
	//  retrying requests in cache until they are done, we'll have to put in a
	//  state like `{p, ts, retrying: boolean}`.
	//  We'd then have to add `cache.setRetrying()` and if it's retrying, we
	//  could use a different expiry.
	const pruneExpiredCache = () => {
		// Debounce expiry is 3 seconds, so anything older than now - 3s is expired
		const exp = +now.subtract(3, 's')

		activeArgsCache.forEach((v, k, c) => {
			if (v.ts < exp) c.delete(k)
		})
	}

	// Periodically prune expired cache for memory
	setInterval(pruneExpiredCache, duration(15, 's'))

	const httpFactory = (method: HttpReqMethod) => {
		const methodLower = method.toLowerCase() as Lowercase<HttpReqMethod>
		const optsForMethod: Writable<HttpOpts> = opts[methodLower] || {}

		// GET requests use cache by default
		const defaultShouldUseCache = method === 'GET'
		const defaults = normalizeOpts({ ...opts, ...optsForMethod })
		// This is a shared promise for waiting on the `handleApiAuthError`. Since,
		// this is specific to API calls, we cut down on some auth0 churning, by
		// only allowing a single one at a time.
		// let apiAuthErrorPromise: Promise<void> | null = null

		const cacheForArgs = (url: string, headers: any, data: any) => {
			// Delete the timestamp
			const headersWithoutDeviceTs = { ...headers }
			delete headersWithoutDeviceTs['X-DEVICETS']

			// Request id is the stable stringified hex digest of the object
			const reqId = `${hash(stringify([method, url, headersWithoutDeviceTs, data]))}`

			return {
				reqId,

				get: <R>(): Promise<R> | null => {
					pruneExpiredCache()

					return activeArgsCache.get(reqId)?.p || null
				},

				set: <R>(p: Promise<R>) => {
					activeArgsCache.set(reqId, { p, ts: Date.now() })
				},

				delete: () => activeArgsCache.delete(reqId),
			}
		}

		return <R>(
				pathOrUrl: string,
				extra: HttpExtra<State, Extra> = defaultExtra,
			): ThunkAction<Promise<R>, State, any, UnknownAction> =>
			async (dispatch) => {
				const {
					cache: shouldUseCache = defaultShouldUseCache,
					data,
					force = false,
					onError = opts.onError,
				} = extra
				const retry = normalizeRetry(extra.retry, defaults.retry)

				const rejectAuth = (
					error: any = new Error('HTTP authentication error.'),
					reqId?: string,
				) => {
					dispatch(httpUnauthorizedAction())
					dispatch(onError(extra, error, reqId))

					return error
				}

				const getHeaders = async (url: string, body: string | undefined) => {
					const headersState = await dispatch(getHttpHeaders(extra, url, method, body))

					if (headersState.requiresAuth && !headersState.isAuthorized) {
						throw rejectAuth()
					}

					return normalizeHeaders(headersState.header)
				}

				const body = normalizeHttpData(data)
				const url = await defaults.normalizeUrl(pathOrUrl)
				const headers = await getHeaders(url, body)

				const cache = shouldUseCache ? cacheForArgs(url, headers, body) : null
				const activePromiseForArgs = cache?.get<R>()
				const reqId = cache?.reqId || v4()

				const fetchLifecycle = (handler?: HttpLifecycleHandler<State, Extra>) => {
					handler && dispatch(handler(extra, reqId))
				}

				// If force is 'soft' or false, then use debounce promise.
				//
				// NOTE: I'm not sure why, but JetBrains thinks this "can be simplified
				//  to `!force`", which is obviously untrue.
				if (force !== true && activePromiseForArgs) {
					fetchLifecycle(onPendingFetch)

					return activePromiseForArgs.finally(() => {
						fetchLifecycle(onAfterFetch)
					})
				}

				let retryInterval = 1000
				let retryIncrement = 1.2

				const createReqPromise = async (): Promise<R> => {
					// We make sure the request doesn't time out if the retry interval gets
					// too long. This means the timeout is always at least 1 sec after retry
					// interval.
					const reqTimeoutDuration = Math.max(REQ_TIMEOUT_MS, retryInterval + 1000)

					// Set the request timeout.
					const timeoutId = setTimeout(() => cache?.delete(), reqTimeoutDuration)

					// Define closures for error object, response, and status. Default status
					// is for cases where request fails completely, such as no network.
					let err
					let res: Maybe<Response>
					let status = 0

					// Fetch can fail if there's no network.
					try {
						// NOTE: This assignment is done to support iOS 9+ pairing. Passing undefined headers
						//  to fetch causes errors.
						const init = { body, method, ...(headers ? { headers } : {}) }

						res = await fetch(url, init)
						status = res.status
					} catch (e) {
						console.error('Fetch error', e)
						err = e
					}

					// Clear the request timeout immediately after we get a response. This
					// doesn't change the response for anything listening to the promise,
					// but does allow new requests of the same type to come in.
					clearTimeout(timeoutId)

					const retries = retryState.get(reqId) || 0
					const hasMaxRetries = retries >= retry.max
					const shouldRetry = !res?.ok && !hasMaxRetries && retry.shouldRetry(status)

					if (!res || shouldRetry) {
						if (!shouldRetry) {
							cache?.delete()

							throw err
						}

						// Set the retry state
						retryState.set(reqId, retries)

						if (onRetry) dispatch(onRetry(extra, reqId, retries))

						// Wait for the current retry interval before trying again
						await timeoutPromise(retryInterval)

						// Increment the retries
						retryState.set(reqId, retries + 1)

						// Increase retry interval by an increasing amount each time, up to 60 seconds
						// (although, it's unlikely to reach 60 seconds based on the algo).
						// Some randomization is also added to protect the server a bit.
						retryInterval = Math.min(
							60_000,
							Math.max(
								retryInterval,
								Math.floor(retryInterval * (retryIncrement * random(0.8, 1.2, true))),
							),
						)
						retryIncrement += 1 / (retries + 2) ** 2.5

						// Finally, retry the request.
						return createReqPromise()
					}

					// Handle request error
					if (!res.ok) {
						// At this point, we shouldn't retry, so clear the cache so new requests can come
						cache?.delete()

						if (status === ReqErrorCode.BAD_REQUEST || status === ReqErrorCode.FORBIDDEN) {
							let resObject: any = {}

							try {
								resObject = await res.json()
							} catch {
								/* ignore */
							}

							const code: APIErrorCode = resObject?.errors?.code ?? APIErrorCode.UNKNOWN_ERROR

							console.log({ resObject })

							throw createAPIError(code)
						}

						// NOTE: This casting isn't correct, but it shouldn't be an issue
						//  either. We don't need the error code list to exhaustive.
						throw createReqError(status as ReqErrorCode)
					}

					return await parseResponse(res)
				}

				fetchLifecycle(onBeforeFetch)
				fetchLifecycle(onPendingFetch)

				const newActivePromiseForArgs = createReqPromise()
					.catch((e: unknown) => {
						if (isReqErrorType(e, ReqErrorCode.UNAUTHORIZED)) throw rejectAuth(e, reqId)

						dispatch(onError(extra, e, reqId))

						throw e
					})
					.finally(() => {
						// Clear the retry state if we have one.
						retryState.delete(reqId)
						fetchLifecycle(onAfterFetch)
					})

				cache?.set(newActivePromiseForArgs)

				return newActivePromiseForArgs
			}
	}

	return {
		delete: httpFactory('DELETE'),
		get: httpFactory('GET'),
		post: httpFactory('POST'),
		put: httpFactory('PUT'),
	}
}

const parseResponse = async (res: Response): Promise<any> => {
	// We attempt to parse response but some responses are empty, so we catch
	// parse errors and simply return nothing.
	try {
		// NOTE: We parse text instead of res.json, b/c the JSON method will
		//  throw synchronously causing the debugger to pause as an unhandled
		//  promise rejection (even though it's handled). We can remove this
		//  and go back to using res.json() when we have full native async
		//  / await support. res.text() always returns a string and we can
		//  catch any JSON parse errors ourselves.
		const text = await res.text()

		if (!text) return undefined as any

		return JSON.parse(text)
	} catch {
		/**/
	}

	return undefined as any
}
