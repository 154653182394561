import { selectUnaryEvery } from '@eturi/util'
import { createSelector } from '@reduxjs/toolkit'
import { isTrialEligible$, purchaseInfo$, redeemedBenefit$ } from '../reducers/index.js'
import {
	type AccountSKU,
	intToFloatPrice,
	isStripeCouponBenefit,
	type PurchaseInfo,
	toPriceStr,
} from '../types/index.js'
import { isPaidSub$, isStripeSub$, nextSKU$ } from './access.js'

const nextSKUPriceStr = ({ price_usd }: AccountSKU, purchaseInfo: Maybe<PurchaseInfo>) =>
	toPriceStr(intToFloatPrice(purchaseInfo?.price_next_uscent || price_usd))

export const nextSKUPriceStr$ = /*@__PURE__*/ createSelector(
	isStripeSub$,
	nextSKU$,
	purchaseInfo$,
	redeemedBenefit$,
	(isStripe, nextSKU, purchaseInfo, redeemedBenefit) => {
		if (!redeemedBenefit) return nextSKUPriceStr(nextSKU, purchaseInfo)

		const discountMod =
			(
				isStripe &&
				isStripeCouponBenefit(redeemedBenefit) &&
				redeemedBenefit.details.limit_skus?.includes(nextSKU.sku)
			) ?
				(100 - (redeemedBenefit.details.stripe_percent_off || 0)) / 100
			:	1

		return toPriceStr(intToFloatPrice(discountMod * (nextSKU?.price_usd || 0)))
	},
)

export const shouldSetNotTrialEligible$ = /*@__PURE__*/ createSelector(
	isPaidSub$,
	isTrialEligible$,
	selectUnaryEvery,
)
