import type { AccountSub } from './AccountSub.js'
import { DEFAULT_SUB } from './AccountSub.js'
import type { SKUDef } from './SKUDef.js'
import { DEFAULT_SKU_DEF, getTierWeight } from './SKUDef.js'

export type AccountSKU = SKUDef & AccountSub

export const DEFAULT_ACCOUNT_SKU = {
	...DEFAULT_SUB,
	...DEFAULT_SKU_DEF,
} satisfies AccountSKU

export const mapToHighestSKU = (skus: Maybe<AccountSKU[]>, skipCancelled: boolean): AccountSKU => {
	if (!skus) return DEFAULT_ACCOUNT_SKU

	return skus.reduce(
		(highestSKU, currentSKU) =>
			skipCancelled && currentSKU.cancelled ? highestSKU : _getHighestSKU(highestSKU, currentSKU),
		DEFAULT_ACCOUNT_SKU,
	)
}

const _getHighestSKU = (s1: AccountSKU, s2: AccountSKU): AccountSKU => {
	const t1 = getTierWeight(s1.tier_def)
	const t2 = getTierWeight(s2.tier_def)

	if (t1 === t2) {
		return s1.cancelled ? s2 : s1
	}

	return t1 > t2 ? s1 : s2
}
