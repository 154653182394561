import { bToHex, pemDecode } from '@op/util'

/**
 * Returns the SHA1 hash of the public key string as a hex digest
 * @param publicKeyPem Public key to generate the id from
 */
export const generateKeyId = async (publicKeyPem: string) => {
	const keyIdBytes = await crypto.subtle.digest('SHA-1', pemDecode(publicKeyPem))
	return bToHex(keyIdBytes)
}
