import { assertNotNullish, setIfNotEqual } from '@eturi/util'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import isEqual from 'react-fast-compare'
import { resetAction } from '../actions/index.js'
import { bindCreateAsyncThunkToState } from '../bindCreateAsyncThunkToState.js'
import type { HttpExtra } from '../http.js'
import type {
	AppPrefs,
	InitState,
	RawAppPrefs,
	RawEmailNotifPref,
	SThunkState,
} from '../types/index.js'
import { DEFAULT_PREFS, parseRawEmailNotifPref, parseRawPrefs } from '../types/index.js'

export type PrefsState = InitState & {
	readonly prefs: AppPrefs
	readonly marketing_email_notifs: boolean
}

export type WithPrefsState = {
	readonly prefs: PrefsState
}

const initialState: PrefsState = {
	isInit: false,
	marketing_email_notifs: false,
	prefs: DEFAULT_PREFS,
}

export const prefsSlice = /*@__PURE__*/ createSlice({
	name: 'prefs',
	initialState,
	reducers: {
		setMarketingEmailNotifPref(s, a: PayloadAction<boolean>) {
			s.marketing_email_notifs = a.payload
		},

		setPrefs(s, a: PayloadAction<AppPrefs>) {
			setIfNotEqual(s, 'prefs', a.payload)
		},
	},
	extraReducers: (builder) =>
		builder
			.addCase(resetAction, () => initialState)
			.addCase(fetchPrefs.fulfilled, (s) => {
				s.isInit = true
			}),
})

export const { setMarketingEmailNotifPref, setPrefs } = prefsSlice.actions

////////// Thunks //////////////////////////////////////////////////////////////

export type PrefsThunkState = SThunkState & WithPrefsState

const createAsyncThunk = /*@__PURE__*/ bindCreateAsyncThunkToState<PrefsThunkState>()

// FIXME: try/catch client impl
export const fetchMarketingEmailNotifPref = /*@__PURE__*/ createAsyncThunk(
	'prefs/marketingEmailNotifs/fetch',
	async (extra: HttpExtra = {}, { dispatch, extra: { http } }) => {
		const rawEmailPref = await dispatch(
			http.get<RawEmailNotifPref>(
				'/account_attributes?attr_keys=account.marketing_email_optin',
				extra,
			),
		)

		dispatch(setMarketingEmailNotifPref(parseRawEmailNotifPref(rawEmailPref)))
	},
)

export const fetchPrefs = /*@__PURE__*/ createAsyncThunk(
	'prefs/fetch',
	async (extra: HttpExtra = {}, { dispatch, extra: { http } }) => {
		const rawPrefs = await dispatch(
			http.get<Maybe<RawAppPrefs>>('/account_attributes?attr_keys=webapp', extra),
		)

		assertNotNullish(rawPrefs, 'RawAppPrefs')

		const prefs = parseRawPrefs(rawPrefs)

		if (prefs) dispatch(setPrefs(prefs))
	},
	{
		condition: (arg, api) => {
			if (!arg?.force && isPrefsInit$(api.getState())) return false
		},
	},
)

// FIXME: try/catch client impl
export const updateMarketingEmailNotifPref = /*@__PURE__*/ createAsyncThunk(
	'prefs/marketingEmailNotifs/update',
	async (enabled: boolean, { dispatch, getState, extra: { http } }) => {
		const rollbackValue = areMarketingEmailsEnabled$(getState())

		dispatch(setMarketingEmailNotifPref(enabled))

		try {
			await dispatch(
				http.put('/account_attributes', {
					data: [{ 'account.marketing_email_optin': enabled ? 'true' : 'false' }],
				}),
			)
		} catch (e) {
			dispatch(setMarketingEmailNotifPref(rollbackValue))
			throw e
		}
	},
)

export const updatePrefs = /*@__PURE__*/ createAsyncThunk(
	'prefs/update',
	async (prefUpdates: Partial<AppPrefs>, { dispatch, getState, extra: { http } }) => {
		const rollbackValue = prefs$(getState())
		const updatedPrefs = { ...rollbackValue, ...prefUpdates }

		// No need to update on server if prefs haven't changed
		if (isEqual(updatedPrefs, rollbackValue)) return

		dispatch(setPrefs(updatedPrefs))

		try {
			await dispatch(http.put('/account_attributes', { data: [{ webapp: updatedPrefs }] }))
		} catch (e) {
			dispatch(setPrefs(rollbackValue))
			throw e
		}
	},
)

////////// Selectors ///////////////////////////////////////////////////////////

const state$ = <T extends WithPrefsState>(s: T) => s.prefs

export const prefs$ = /*@__PURE__*/ createSelector(state$, (s) => s.prefs)
export const areMarketingEmailsEnabled$ = /*@__PURE__*/ createSelector(
	state$,
	(s) => s.marketing_email_notifs,
)
export const isPrefsInit$ = /*@__PURE__*/ createSelector(state$, (s) => s.isInit)
export const rawSystemOfUnits$ = /*@__PURE__*/ createSelector(prefs$, (s) => s.system_of_units)
