import { useChgState, useFn, useThrottle, useTimeout } from '@eturi/react'
import noop from 'lodash/noop'
import { useEffect, useLayoutEffect } from 'react'
import { useCarousel } from './useCarousel'

/**
 * Handler that is called when the overlay changes.
 * @param {boolean} isVisible Whether the carousel is changing to be visible or not
 * @param {boolean} isAutoHide Whether the change is occurring due to timeout-based automatic hide
 * @return {boolean | void} If return is `false`, it acts like `preventDefault` and cancels operation
 */
export type OnCarouselOverlayChange = (isVisible: boolean, isAutoHide: boolean) => boolean | void

export const useCarouselOverlay = (
	onChange: OnCarouselOverlayChange = noop,
	isVisibleOverride?: boolean,
) => {
	const carousel = useCarousel()
	const handleChange = useFn(onChange)
	const [setAutoHideTimeout, clearAutoHideTimeout] = useTimeout()

	const [isVisible, setVisible] = useChgState(() => isVisibleOverride ?? true)
	const [hasToggled, setToggled] = useChgState(false)

	// We throttle to not allow overly quick toggling
	const toggleOverlay = useThrottle((isAutoHide: boolean) => {
		const isNextVisible = !isVisible

		if (handleChange(isNextVisible, isAutoHide) === false) return

		setVisible(isNextVisible)
	}, 500)

	useEffect(() => {
		const offTap = carousel.onTap((isScaled) => {
			if (isScaled) return

			setToggled(true)
			toggleOverlay(false)
		})

		// Hide the overlay if any zoom occurs
		const offZoom = carousel.onZoom(() => {
			setToggled(true)

			if (handleChange(false, false) === false) return

			setVisible(false)
		})

		return () => {
			offTap()
			offZoom()
		}
	}, [])

	useEffect(() => {
		if (hasToggled) return clearAutoHideTimeout()
		setAutoHideTimeout(() => toggleOverlay(true), 5000)
	}, [hasToggled])

	useLayoutEffect(() => {
		if (isVisibleOverride != null) setVisible(isVisibleOverride)
	}, [isVisibleOverride])

	return isVisible
}
