import { ImmutSet } from '@eturi/util'
import type { LatLng } from '@op/services'
import { castImmutSet, resetAction, toIntAction } from '@op/services'
import { getISODateStr } from '@op/util'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { createSliceTransformer } from 'rtk-slice-transformer'
import { fetchAllLocationHistory } from '../thunks/location-history'
import type { LocatorModalState } from '../types'

// export const sanitizeGeoUIActions = (action: Actions) =>
// 	action.type === SET_DESELECTED_USER_IDS ? stripPayload(action) : action

export type GeoUIState = {
	readonly activeLocationDeviceId: Maybe<string>
	readonly activeLocationTS: Maybe<number>
	readonly currentISODateStr: string
	readonly deselectedUserIds: ImmutSet<string>
	readonly isFetchingLocationHistory: boolean
	readonly isLocationDrawerCollapsed: boolean
	readonly isLocationHistoryEnabled: boolean
	readonly isPlacesVisible: boolean
	readonly locatorModalState: Maybe<LocatorModalState>
	readonly mapTypeId: Maybe<google.maps.MapTypeId>
	readonly userLocationData: Maybe<LatLng>
}

export type WithGeoUIState = {
	readonly geoUI: GeoUIState
}

const initialState: GeoUIState = {
	activeLocationDeviceId: null,
	activeLocationTS: null,
	currentISODateStr: getISODateStr(),
	deselectedUserIds: new ImmutSet(),
	isFetchingLocationHistory: false,
	isPlacesVisible: true,
	isLocationDrawerCollapsed: true,
	isLocationHistoryEnabled: true,
	locatorModalState: null,
	mapTypeId: null,
	userLocationData: null,
}

export const geoUISlice = /*@__PURE__*/ createSlice({
	name: 'geoUI',
	initialState,
	reducers: {
		setActiveLocationDeviceId(s, a: PayloadAction<Maybe<string>>) {
			s.activeLocationDeviceId = a.payload
		},

		setActiveLocationTS(s, a: PayloadAction<Maybe<number>>) {
			s.activeLocationTS = a.payload
		},

		setDeselectedUserIds(s, a: PayloadAction<ImmutSet<string>>) {
			castImmutSet<GeoUIState>(s).deselectedUserIds = a.payload
		},

		setIsLocationDrawerCollapsed(s, a: PayloadAction<boolean>) {
			s.isLocationDrawerCollapsed = a.payload
		},

		setLocationHistoryEnabled(s, a: PayloadAction<boolean>) {
			s.isLocationHistoryEnabled = a.payload
		},

		setLocatorModalState(s, a: PayloadAction<Maybe<LocatorModalState>>) {
			s.locatorModalState = a.payload
		},

		setMapTypeId(s, a: PayloadAction<Maybe<google.maps.MapTypeId>>) {
			s.mapTypeId = a.payload
		},

		setPlacesVisible(s, a: PayloadAction<boolean>) {
			s.isPlacesVisible = a.payload
		},

		setUserLocationData(s, a: PayloadAction<LatLng>) {
			s.userLocationData = a.payload
		},

		updateLocatorISOStr(s) {
			s.currentISODateStr = getISODateStr()
		},
	},
	extraReducers: (builder) =>
		builder
			.addCase(resetAction, () => initialState)
			.addCase(fetchAllLocationHistory.pending, (s) => {
				s.isFetchingLocationHistory = true
			})
			.addMatcher(
				isAnyOf(fetchAllLocationHistory.fulfilled, fetchAllLocationHistory.rejected),
				(s) => {
					s.isFetchingLocationHistory = false
				},
			),
})

export const {
	setActiveLocationDeviceId,
	setActiveLocationTS,
	setDeselectedUserIds,
	setIsLocationDrawerCollapsed,
	setLocationHistoryEnabled,
	setLocatorModalState,
	setMapTypeId,
	setPlacesVisible,
	setUserLocationData,
	updateLocatorISOStr,
} = geoUISlice.actions

export const geoUISliceTransformer = /*@__PURE__*/ createSliceTransformer(
	geoUISlice,
	(s) => ({
		...s,
		// Don't send user location to Sentry
		userLocationData: Boolean(s.userLocationData),
	}),
	(a) =>
		setDeselectedUserIds.match(a) ? toIntAction(a)
		: setUserLocationData.match(a) ? null
		: a,
)

// NOTE: This needs to be used if we ever persist this state
export const rehydrateGeoUIState = (p: Partial<GeoUIState>): GeoUIState => ({
	...initialState,
	...p,
	deselectedUserIds: new ImmutSet(p.deselectedUserIds),
})

////////// Selectors ///////////////////////////////////////////////////////////

const state$ = <T extends WithGeoUIState>(s: T) => s.geoUI

/**
 * @deprecated Do not use! This is a state that is part of a composite master state
 */
export const activeLocationDeviceId$ = /*@__PURE__*/ createSelector(
	state$,
	(s) => s.activeLocationDeviceId,
)

export const activeLocationTS$ = /*@__PURE__*/ createSelector(state$, (s) => s.activeLocationTS)
export const deselectedGeoUserIds$ = /*@__PURE__*/ createSelector(state$, (s) => {
	if (!s.deselectedUserIds.has) return new ImmutSet<string>()
	return s.deselectedUserIds
})
export const isFetchingLocationHistory$ = /*@__PURE__*/ createSelector(
	state$,
	(s) => s.isFetchingLocationHistory,
)
export const isLocationHistoryEnabled$ = /*@__PURE__*/ createSelector(
	state$,
	(s) => s.isLocationHistoryEnabled,
)
export const isLocationDrawerCollapsed$ = /*@__PURE__*/ createSelector(
	state$,
	(s) => s.isLocationDrawerCollapsed,
)
export const isPlacesVisible$ = /*@__PURE__*/ createSelector(state$, (s) => s.isPlacesVisible)
export const locatorModalState$ = /*@__PURE__*/ createSelector(state$, (s) => s.locatorModalState)
export const locatorCurrentISOStr$ = /*@__PURE__*/ createSelector(
	state$,
	(s) => s.currentISODateStr,
)
export const mapTypeId$ = /*@__PURE__*/ createSelector(state$, (s) => s.mapTypeId)
export const userLocationData$ = /*@__PURE__*/ createSelector(state$, (s) => s.userLocationData)
