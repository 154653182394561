import { startOf } from '@eturi/date-util'
import { pick } from '@eturi/util'
import forOwn from 'lodash/forOwn.js'
import type { DownloadSampleIdToSampleMap, DownloadUrls } from './DownloadUrls.js'
import type { DecoratedSampleUpdatePayload, SamplesUpdateMap } from './SamplePayloads.js'
import type { AnalysisOcrHits, SampleOcrHits, SampleOcrSummary } from './VewOcrHits.js'
import type { SampleProtection } from './VewProtection.js'

/**
 * NOTE: All history should add 1 for "today" when displaying, per Dustin. This
 *  means 14 days of real history, plus whatever today holds. Only use
 *  this value when displaying to user.
 */
export const MAX_SAMPLE_HISTORY_DAYS = 14

/**
 * Sample types available to request. If no args are passed to requests that
 * have this option then ['sample', 'thumb'] is used. 'analysis' previously
 * existed, but no longer does.
 */
export type SampleType = 'screen' /* Screenshot image */ | 'thumb' /* Thumbnail image*/

/** Union of sparse or full decorated samples */
export type AnyDecoratedSample = DecoratedSparseSample | DecoratedSample

/** A map containing a potential mix of both DecoratedSparseSample and DecoratedSample */
export type AnyDecoratedSampleMap = {
	readonly [imageId: string]: AnyDecoratedSample
}

/** Baseline response model for sample metadata */
type BaseSampleMetadataRes = {
	// Max sample timestamp is included in {start_after: ts} calls
	readonly max_sample_ts?: number

	// Max updated timestamp is included in {updated_after: ts} calls
	readonly max_updated_ts?: number

	// Min sample timestamp is included in {recent_before: ts} calls
	readonly min_sample_ts?: number
}

export type SampleImage = {
	readonly h?: number
	readonly iv: string
	readonly src?: string
	readonly url: string
	readonly w?: number
}

/**
 * Sparse decorated sample (containing the subset of stuff for DecoratedSample
 * that we get from the sparse call)
 */
export type DecoratedSparseSample = {
	readonly analysis?: AnalysisOcrHits
	// Day timestamp is used for grouping by day
	readonly dayTs: number
	readonly device_id: string
	// Expiration of download urls
	readonly dlExpiry?: number
	readonly encKey?: string
	readonly hits: SampleOcrHits
	readonly imageId: string
	readonly sample_id: string
	readonly sample_ts: number
	readonly screen?: SampleImage
	readonly screenIdx: number
	readonly thumb?: SampleImage
	readonly updated_ts: number
	readonly user_id: string
}

/** A decorated sample, containing the stuff we really need */
export type DecoratedSample = DecoratedSparseSample & {
	readonly encKey: string
	readonly dlExpiry: number
	readonly screen: SampleImage
	readonly thumb: SampleImage
}

export type DecoratedSparseSampleMap = {
	readonly [imageId: string]: DecoratedSparseSample
}

/** Potential error stuff */
export type SampleError = {
	// Specific ID of error (see table below)
	readonly errno: number

	// Debugging level text message (English)
	readonly msg: string

	// Exception trace or other details to reproduce the error
	readonly trace?: string
}

/** Additional info on the sample */
export type SampleInfo = {
	// The updated_ts of the automated Vew config object used. If set this
	// indicates that this was an automated sample.
	readonly auto_ts?: number

	// The parent device ID that triggered on-demand Vew. Absent if automated.
	// If set, indicates that this was an on-demand sample.
	readonly requested_by?: string

	// The request_ts value from the Vew Now vew.on_demand message to make it
	// unambiguous which request was acted on.
	readonly request_ts?: number

	// Number of screens in this object. Will be filled in by the server.
	// This is useful if the screens detail information is filtered out.
	readonly screen_count: number
}

/** The full sample metadata object */
export type SampleMetadata = {
	// 	Identifies the ID/version of the component that created the sample
	// 	(e.g. vew_android/1.0.2)
	readonly agent?: string

	// MSSE when this object was first persisted by the server. Will be
	// filled in by the server on create.
	readonly created_ts: number

	// The ID of the device creating the sample. Will be filled in by
	// the server is absent
	readonly device_id: string

	// Indicates that this sample is deleted if true. Any metadata in the
	// sample must be considered invalid and may be removed by the server.
	readonly deleted?: boolean

	// Identifies and describes the error, if one occurred. Note: This attribute
	// it optional, but if it is present, it must comply with the following spec.
	readonly error?: SampleError

	// Contains keyword category hit counts per screen. The order of screens must
	// match the order within screens. It is allowed to have a null object
	// for a screen.
	readonly ocr_summary?: SampleOcrSummary[]

	// Data encryption information
	readonly protection: SampleProtection

	// Sample identifier that is unique within the account and has
	// no other meaning.
	readonly sample_id: string

	// Attributes describing a sample
	readonly sample_info: SampleInfo

	// MSSE when the sample was taken
	readonly sample_ts: number

	// Information about each screen of a data sample. The length of this list
	// is the number of screens and sample files. Clients should use the same
	// index for the same screens for subsequent samples.
	readonly screens: SampleScreen[]

	// Identifies this type of object and version. Set to "meta.1"
	readonly type: string

	// MSSE when this object was last persisted by the server. Will be filled
	// in by the server on create or update.
	readonly updated_ts: number

	// The ID of the child user for the device at the moment the sample was taken.
	readonly user_id: string
}

/**
 * A map of sampleId to SampleMetadata object. We get this from the full
 * get_sample_meta call
 */
export type SampleMetadataMap = {
	readonly [sampleId: string]: SampleMetadata
}

/** The full sample metadata response. Contains DownloadUrls info. */
export type SampleMetadataRes = (BaseSampleMetadataRes & DownloadUrls) & {
	readonly sample_meta: SampleMetadataMap
}

/** Contains info about each sample screen */
export type SampleScreen = {
	// Indicates that this screen is deleted if true. Any metadata for the screen
	// incl thumbnail and analysis result must be considered invalid and may be
	// removed by the server.
	readonly deleted?: boolean

	// Compression info, e.g. quality, downsample factor
	readonly screen_comp_attrs?: string

	// Pixel dimensions of screenshot image. A width x height value, e.g. "1024x768"
	readonly screen_dim: string

	// Identifies the screen of this sample, e.g. "Samsung SyncMaster"
	readonly screen_id?: string

	// Size in bytes of screenshot after initial compression (e.g downsample, JPEG)
	readonly screen_size?: number

	// Size in bytes of original screenshot
	readonly screen_size_orig?: number

	// Degree of similarity (0 is none, 1 is full) of screenshot as compared to
	// previous screenshot, using a basic comparison technique.
	readonly similarity?: number

	// A width x height value, e.g. "1024x768"
	readonly thumbnail_dim: string

	// Size in bytes of thumbnail
	readonly thumbnail_size?: number
}

/**
 * This is sample metadata that has been specifically filtered to remove
 * nearly all data and is used for generating a virtual list.
 */
export type SparseSampleMetadata = {
	readonly device_id: string
	readonly deleted: boolean
	readonly ocr_summary?: SampleOcrSummary[]
	readonly sample_id: string
	readonly sample_ts: number
	readonly screens: SampleScreen[]
	readonly updated_ts: number
	readonly user_id: string
}

/** This is the sparse equivalent to SampleMetadataMap */
export type SparseSampleMetadataMap = {
	readonly [sampleId: string]: SparseSampleMetadata
}

/** This is the sparse equivalent to SampleMetadataRes */
export type SparseSampleMetadataRes = BaseSampleMetadataRes & {
	readonly sample_meta: SparseSampleMetadataMap
}

/** A map of encrypted temp key to decrypted temp key */
export type TempKeyMap = {
	readonly [encKey: string]: string
}

//////////////////////////////////////////////////
/// Type transforms and helpers
//////////////////////////////////////////////////

// TODO: Dr. M is implementing a filter that will allow filtering on lists as
//  well. This will allow us to use `'screens.deleted'` filter which will only
//  return the 'deleted' field on SampleMetadata.screens objects.
export const SPARSE_SAMPLE_FILTER = [
	'device_id',
	'deleted',
	'ocr_summary',
	'sample_id',
	'sample_ts',
	'screens',
	'updated_ts',
	'user_id',
] satisfies (keyof SampleMetadata)[]

const PICK_DECORATED = ['device_id', 'sample_id', 'sample_ts', 'updated_ts', 'user_id'] as const

export type WithAnalysis<T extends AnyDecoratedSample> = T & { readonly analysis: AnalysisOcrHits }
export type WithFullSample<T extends AnyDecoratedSample> = T & DecoratedSample

/**
 * Determines whether a particular sample can fetch screen ciphertext. Image must
 * not be sparse, must not have ciphertext, and _must_ have a valid download url.
 *
 * @param sample The sample to inspect for ciphertext fetching
 * @param type Type of image to check for
 */
export const canFetchCiphertextForType = <T extends AnyDecoratedSample>(
	sample: T,
	type: SampleType,
): sample is WithFullSample<T> => _canFetchType(sample, type) && !hasExpiredDownloadUrl(sample)

export const canFetchAnalysis = <T extends AnyDecoratedSample>(
	sample: T,
): sample is WithFullSample<WithAnalysis<T>> => isFullSample(sample) && !hasAnalysis(sample)

/**
 * Determines whether a particular sample can fetch its download urls for screen. Sample
 * must not be sparse, must not have screen ciphertext, and must have an expired or
 * non-existent download url. If all these are met, we can say that we need to fetch fresh
 * download urls for the sample.
 *
 * @param sample The sample to inspect for image download urls
 * @param type The type of image to check for
 */
export const canFetchDlUrlsForType = (sample: AnyDecoratedSample, type: SampleType) =>
	_canFetchType(sample, type) && hasExpiredDownloadUrl(sample)

const DIM_SPLIT = /x/i

const parseDims = (dimsStr: string): MPick<SampleImage, 'h' | 'w'> => {
	const [widthStr, heightStr] = dimsStr.split(DIM_SPLIT)

	return {
		h: Number.parseInt(heightStr) || undefined,
		w: Number.parseInt(widthStr) || undefined,
	}
}

const DEFAULT_HITS: SampleOcrHits = {}

/**
 * Decorates a given sample, outputting any individual samples (thumb / screen)
 * as separate objects.
 */
export const decorateSample = (
	sample: SampleMetadata,
	dlExpiry: number,
	urls: DownloadSampleIdToSampleMap,
	keyId: string,
): DecoratedSample[] => {
	// We use a loop instead of filter -> map because screen are indexed even if a
	// screen is deleted we need to keep track of that index
	const decoratedSamples: DecoratedSample[] = []

	try {
		// Pick the base attributes
		const partialDecorated = pick(sample, PICK_DECORATED)

		// Extract stuff needed for decorating
		const {
			protection: { ivs, keys },
			sample_id,
			screens,
		} = sample
		const ocr_summary = sample.ocr_summary || []

		// We decorate this here, so we can sort by days later
		const dayTs = +startOf(sample.sample_ts, 'd')

		// Encrypted private key for the current key id
		const encKey = keys[keyId]

		// NOTE: We don't consider this decorated without the encrypted temp key.
		//  We may want to throw an error in the future, which would allow us to
		//  re-fetch the encryptedPrivate from the server. (A missing key id, means
		//  our encryptedPrivate doesn't match, since the keyId is derived from the
		//  public key).
		if (!encKey) {
			console.error('Missing encrypted tempKey for public key')
			return []
		}

		// The download urls for the given sample_id
		const { screen, thumb } = urls[sample_id]

		for (let i = 0; i < screens.length; i++) {
			const sampleScreen = screens[i]

			if (sampleScreen.deleted) continue

			decoratedSamples.push({
				...partialDecorated,
				dayTs,
				dlExpiry,
				encKey,
				hits: ocr_summary[i]?.cat || DEFAULT_HITS,
				imageId: toImageId(sample_id, i),
				screenIdx: i,
				screen: {
					...parseDims(sampleScreen.screen_dim),
					iv: ivs[`screen_${i}`],
					url: screen[i].url,
				},
				thumb: {
					...parseDims(sampleScreen.thumbnail_dim),
					iv: ivs[`thumb_${i}`],
					url: thumb[i].url,
				},
			})
		}
	} catch (e) {
		console.error(e, 'Failed to decorate sample')
	}

	return decoratedSamples
}

// FIXME: Currently only deleted samples are taken into account for returning
//  list. We may need to take the sample screens themselves into account, if
//  we ever have more than one screen.
/**
 * Decorates many samples from a SampleMetadataRes. Returns a tuple containing
 * the decorated samples, and any decrypted temp keys as a new TempKeyMap
 *
 * @see decorateSample
 */
export const decorateSamples = (
	{ get_expiry, get_urls, sample_meta }: SampleMetadataRes,
	keyId: string,
	tempKeys: TempKeyMap,
): [DecoratedSampleUpdatePayload, ReadonlySet<string>] => {
	const deleted: string[] = []
	const samples: Writable<SamplesUpdateMap> = {}
	const tempKeysToDecrypt: Set<string> = new Set()

	forOwn(sample_meta, (rawSample, sampleId) => {
		// Collection samples that should be deleted
		if (rawSample.deleted) {
			deleted.push(sampleId)
			return
		}

		for (const sample of decorateSample(rawSample, get_expiry, get_urls, keyId)) {
			const { encKey, imageId } = sample
			// Add the decorated sample
			samples[imageId] = sample

			// Make sure any temp keys are decrypted and stored
			if (!tempKeys[encKey]) tempKeysToDecrypt.add(encKey)
		}
	})

	return [{ deleted, samples }, tempKeysToDecrypt]
}

export function decorateSparseSamples(res: SparseSampleMetadataRes): DecoratedSampleUpdatePayload
export function decorateSparseSamples(
	res: SparseSampleMetadataRes,
	filterDeleted: true,
): DecoratedSparseSampleMap
export function decorateSparseSamples(res: SparseSampleMetadataRes, filterDeleted = false): any {
	const deleted: string[] = []
	const samples: Writable<DecoratedSparseSampleMap> = {}

	forOwn(res.sample_meta, (rawSample, sampleId) => {
		if (rawSample.deleted) {
			!filterDeleted && deleted.push(sampleId)
			return
		}

		for (const decoratedSample of decorateSparseSample(rawSample)) {
			samples[decoratedSample.imageId] = decoratedSample
		}
	})

	return filterDeleted ? samples : { deleted, samples }
}

/**
 * For each screen in a sparse sample, pick the properties for the decorated
 * sample. Create the fileName and unique sampleIdName.
 */
export const decorateSparseSample = (sparse: SparseSampleMetadata): DecoratedSparseSample[] => {
	if (sparse.deleted) return []

	// We use a loop instead of filter -> map because screen are indexed even if a
	// screen is deleted we need to keep track of that index
	const decoratedSparseSamples: DecoratedSparseSample[] = []

	try {
		const partialDecorated = pick(sparse, PICK_DECORATED)
		const dayTs = +startOf(sparse.sample_ts, 'd')
		const { sample_id, screens } = sparse
		const ocr_summary = sparse.ocr_summary || []

		for (let i = 0; i < screens.length; i++) {
			const sampleScreen = screens[i]

			if (sampleScreen.deleted) continue

			decoratedSparseSamples.push({
				...partialDecorated,
				dayTs,
				hits: ocr_summary[i]?.cat || DEFAULT_HITS,
				imageId: toImageId(sample_id, i),
				screenIdx: i,
			})
		}
	} catch (e) {
		console.error(e, 'Failed to decorate sparse sample')
	}

	return decoratedSparseSamples
}

/** Determines whether a sample has analysis */
export const hasAnalysis = <T extends AnyDecoratedSample>(sample: T): sample is WithAnalysis<T> =>
	Boolean(sample.analysis)

/** Determines whether a sample has an expired or non-existent download url */
export const hasExpiredDownloadUrl = (sample: AnyDecoratedSample) =>
	(sample.dlExpiry || Infinity) <= Date.now()

export const hasValidDownloadUrls = (sample: AnyDecoratedSample) =>
	Boolean(sample.screen?.url && sample.thumb?.url && !hasExpiredDownloadUrl(sample))

/** Determines whether a sample has an object url for a particular image type */
export const hasImageSrc = (sample: AnyDecoratedSample, type: SampleType) =>
	sample[type]?.src != null

/** Determines whether a sample is 'sparse' by checking if it has an encrypted tempKey */
export const isSparseSample = (s: AnyDecoratedSample): s is DecoratedSparseSample => !s.encKey
export const isFullSample = (s: AnyDecoratedSample): s is DecoratedSample => !!s.encKey

export const toImageId = (sampleId: string, screenIdx: number) => `${sampleId}:image_${screenIdx}`

const _canFetchType = (sample: AnyDecoratedSample, type: SampleType) =>
	isFullSample(sample) && !hasImageSrc(sample, type)
