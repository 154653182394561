import { assertNotNullish, notEmpty, omit, pick, setIfNotEqual } from '@eturi/util'
import { size } from '@op/util'
import type { Draft, PayloadAction } from '@reduxjs/toolkit'
import { compose, createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit'
import find from 'lodash/find.js'
import forEach from 'lodash/forEach.js'
import isEmpty from 'lodash/isEmpty.js'
import map from 'lodash/map.js'
import some from 'lodash/some.js'
import sortBy from 'lodash/sortBy.js'
import without from 'lodash/without.js'
import moment from 'moment-timezone'
import isEqual from 'react-fast-compare'
import { lruMemoize } from 'reselect'
import { createSliceTransformer } from 'rtk-slice-transformer'
import { removeDeviceFromAllStores, resetAction } from '../actions/index.js'
import { bindCreateAsyncThunkToState } from '../bindCreateAsyncThunkToState.js'
import type { HttpExtra } from '../http.js'
import type {
	ChangeCredentialsRes,
	ChangeEmailBody,
	CreateUserBody,
	InitState,
	ManagedUser,
	RawManagedUser,
	RawUser,
	SThunkState,
	UserUpdateBody,
} from '../types/index.js'
import {
	createReqError,
	isAccountUser,
	isManagedUser,
	normalizeLocale,
	ReqErrorCode,
	UserTypes,
} from '../types/index.js'
import { createResultEqualSelector } from '../util/createResultEqualSelector.js'
import type { AccountAuthArgs } from './account.slice.js'
import { accountEmail$, isLegacyAccount$, reAuth, setAccountEmail } from './account.slice.js'
import type { WithAvatarState } from './avatar.slice.js'

export type UserState = InitState & {
	readonly activeChildId: Maybe<string>
	readonly users: RawUser[]
}

export type WithUserState = {
	readonly user: UserState
}

const initialState: UserState = {
	activeChildId: null,
	isInit: false,
	users: [],
}

type UserUpdate = WithRequired<Partial<RawUser>, 'user_id'>

export const userSlice = /*@__PURE__*/ createSlice({
	name: 'user',
	initialState,
	reducers: {
		retireUser(s, a: PayloadAction<string>) {
			_updateUserState(s, { user_id: a.payload, retired: Date.now() - 1 })
		},

		setActiveChildId(s, a: PayloadAction<string>) {
			s.activeChildId = a.payload
			_validateActiveChildId(s)
		},

		updateStoreUser(s, a: PayloadAction<UserUpdate>) {
			_updateUserState(s, a.payload)
		},
	},
	extraReducers: (builder) =>
		builder
			.addCase(resetAction, () => initialState)
			.addCase(createUser.fulfilled, (s, a) => {
				s.users.push(a.payload)
			})
			.addCase(fetchUsers.fulfilled, (s, a) => {
				const currentUsers = s.users
				// Filter updates to either users that don't exist in current list, or ones that can be
				// updated based on `schedule_ts`. In reality, this is very unlikely to be an issue, unlike
				// with `_updateUserState`, when toggling allowance, where this issue was very easy to
				// reproduce. It requires that a `fetchUsers` call is made before `modifyUser`, but returns
				// its result after `modifyUser`.
				const updatedUsers = a.payload.filter((u) => {
					const currentUser = currentUsers.find(({ user_id }) => user_id === u.user_id)

					if (!currentUser) return true

					return _canUpdateUser(currentUser, u)
				})

				setIfNotEqual(s, 'users', updatedUsers)

				s.isInit = true
				_validateActiveChildId(s)
			})
			.addCase(removeDeviceFromAllStores, (s, { payload: deviceId }) => {
				for (const user of s.users) {
					const devices = without(user.devices, deviceId)

					if (size(devices) !== size(user.devices)) {
						user.devices = devices
					}
				}
			})
			.addMatcher(
				isAnyOf(changeLocale.fulfilled, fetchUser.fulfilled, updateUser.fulfilled),
				(s, { payload: updatedUser }) => {
					_updateUserState(s, updatedUser)
				},
			),
})

export const { retireUser, setActiveChildId, updateStoreUser } = userSlice.actions

const isUserAction = /*@__PURE__*/ isAnyOf(retireUser, setActiveChildId, updateStoreUser)

export const userSliceTransformer = /*@__PURE__*/ createSliceTransformer(
	userSlice,
	(s) => ({
		...s,
		users: s.users
			.filter((u) => !u.retired)
			.map((u) => omit(u, ['account_id', 'birthday', 'email', 'gender', 'img_url', 'user_name'])),
	}),
	(a) => (isUserAction(a) ? null : a),
)

////////// Thunks //////////////////////////////////////////////////////////////

export type UserThunkState = SThunkState & WithAvatarState & WithUserState

const createAsyncThunk = /*@__PURE__*/ bindCreateAsyncThunkToState<UserThunkState>()

type ChangeEmailArgs = AccountAuthArgs & {
	readonly email: string
	readonly password: string
}

export const changeEmail = /*@__PURE__*/ createAsyncThunk(
	'user/changeEmail',
	async (
		{ decryptPemPair, email, generatePasswordHash, password: currentPw }: ChangeEmailArgs,
		{ dispatch, getState, extra: { http } },
	) => {
		const state = getState()
		const currentEmail = accountEmail$(state)
		const isLegacyAccount = isLegacyAccount$(state)

		const [password, upd_password] = await Promise.all([
			// Legacy account just uses plaintext password. Otherwise, we hash.
			isLegacyAccount ? Promise.resolve(currentPw) : generatePasswordHash(currentPw, currentEmail),

			// Legacy account doesn't use `upd_password`. Otherwise, create new hash.
			isLegacyAccount ? Promise.resolve(null) : generatePasswordHash(currentPw, email),
		])

		const data: ChangeEmailBody = {
			login_obj: {
				...(upd_password ? { upd_password } : {}),
				email: currentEmail,
				new_email: email,
				password,
			},
		}

		const { status } = await dispatch(http.put<ChangeCredentialsRes>('/credentials', { data }))

		if (status !== 'email_updated') {
			throw new Error(`Bad status for PUT /credentials ${status}`)
		}

		dispatch(setAccountEmail(email))
		const user_id = accountUser$(getState())!.user_id
		dispatch(updateStoreUser({ email, user_id }))

		// Re-auth on PUT /credentials
		await dispatch(
			reAuth({
				decryptPemPair,
				generatePasswordHash,
				password: currentPw,
			}),
		).unwrap()
	},
)

export const changeLocale = /*@__PURE__*/ createAsyncThunk(
	'user/locale/modify',
	async (locale: string, { dispatch, getState, extra: { http } }) => {
		const accountUser = accountUser$(getState())

		if (!accountUser) throw createReqError(ReqErrorCode.UNAUTHORIZED)

		const changeLocalePartial = {
			locale: normalizeLocale(locale),
			user_id: accountUser.user_id,
		} satisfies UserUpdateBody

		const newAccountUser = await dispatch(
			http.put<Maybe<RawUser>>('/user', { data: [changeLocalePartial] }),
		)

		assertNotNullish(newAccountUser, 'RawUser locale')

		return newAccountUser
	},
)

type CreateUserArgs = {
	readonly time_zone: string
	readonly user_name: string
}

export const createUser = /*@__PURE__*/ createAsyncThunk(
	'/user/create',
	async (arg: CreateUserArgs, { dispatch, extra: { http } }) => {
		if (!(arg.time_zone && arg.user_name)) {
			throw new Error('Missing require param for user creation')
		}

		const createUserPartial: CreateUserBody = {
			type: UserTypes.Managed,
			...arg,
		}

		const user = await dispatch(
			http.post<Maybe<RawUser>>('/user', {
				data: { user_obj: createUserPartial },
			}),
		)

		assertNotNullish(user, 'Create User')

		return user
	},
)

type DeleteUserRes = {
	readonly objects_deleted: number
}

export const deleteUser = /*@__PURE__*/ createAsyncThunk(
	'user/delete',
	async (userId: string, { dispatch, extra: { http } }) => {
		const { objects_deleted } = await dispatch(
			http.delete<DeleteUserRes>(`/user?user_id=${userId}`),
		)

		if (objects_deleted <= 0) throw new Error('No users deleted')

		dispatch(retireUser(userId))
	},
)

type FetchUserArg = HttpExtra & {
	readonly userId: string
}

// FIXME: Make sure we force in places where this is used, since that is new
export const fetchUser = /*@__PURE__*/ createAsyncThunk(
	'user/fetch',
	async ({ userId, ...extra }: FetchUserArg, { dispatch, extra: { http } }) => {
		const res = await dispatch(http.get<Maybe<RawUser[]>>(`/user?user_id=${userId}`, extra))
		const user = res?.[0]

		assertNotNullish(user, 'RawUser')

		return user
	},
	{
		condition: (arg, api) => {
			if (!arg.force && some(rawUsers$(api.getState()), { user_id: arg.userId })) return false
		},
	},
)

export const fetchUsers = /*@__PURE__*/ createAsyncThunk(
	'user/fetchAll',
	async (extra: HttpExtra = {}, { dispatch, extra: { http } }) => {
		const users = await dispatch(http.get<Maybe<RawUser[]>>('/user', extra))

		assertNotNullish(users, 'RawUser[]')

		return users
	},
	{
		condition: (arg, api) => {
			if (!arg?.force && isUsersInit$(api.getState())) return false
		},
	},
)

export const updateUser = /*@__PURE__*/ createAsyncThunk(
	'user/update',
	async (user: UserUpdateBody, { dispatch, extra: { http } }) => {
		const updatedUser = await dispatch(http.put<Maybe<RawUser>>('/user', { data: [user] }))

		assertNotNullish(updatedUser, 'RawUser')

		return updatedUser
	},
)

type SetSameTzForOtherChildrenArgs = {
	readonly tz: string
	readonly userId: string
}

export const setSameTzForOtherChildren = /*@__PURE__*/ createAsyncThunk(
	'user/setTzForOtherChildren',
	async ({ tz, userId }: SetSameTzForOtherChildrenArgs, { dispatch, getState }) =>
		Promise.all(
			rawChildren$(getState())
				.filter((u) => u.user_id !== userId)
				.map(async (u) => {
					await dispatch(updateUserProfile({ ...u, time_zone: tz }))
					return u.user_name || ''
				}),
		),
)

export const setUsersGeoEnabled = /*@__PURE__*/ createAsyncThunk(
	'user/toggleGeoEnabled',
	(enabled: boolean, { dispatch, getState }) => {
		const users = rawSortedFilteredChildren$(getState())

		return Promise.all(
			users.map((u) =>
				dispatch(updateUser({ user_id: u.user_id, user_location_active: enabled })).unwrap(),
			),
		)
	},
)

export const updateUserProfile = /*@__PURE__*/ createAsyncThunk(
	'user/updateProfile',
	(user: RawManagedUser | ManagedUser, { dispatch }) =>
		dispatch(
			updateUser({
				...pick(user, ['birthday', 'gender', 'time_zone', 'user_id', 'user_name']),
				...(user.settings ? { settings: { slot: user.settings.slot } } : {}),
			}),
		),
)

const _selectNonRetiredUsers = <T extends RawUser>(users: T[]): T[] =>
	users.filter((u) => !u.retired)
const _selectRawChildren = (u: RawUser[]): RawManagedUser[] => u.filter(isManagedUser)
const _selectRawSortedChildren = (users: RawManagedUser[]): RawManagedUser[] =>
	sortBy(users, (u) => (u.user_name || '').toLowerCase())

/**
 * Memoize the composition of sorting and filtering, since this will run with
 * some frequency. Note that this is a non-selector equivalent of the selector
 * `getRawSortedFilteredChildren` below.
 *
 * This rather annoying logic is used, so we can guarantee sort order when
 * selecting the `activeChildId` when the `_validateActiveChildId` function finds
 * a mismatch.
 *
 * Initially I tried to do this in a selector, but realized there were issues.
 * For example, if the state had no `activeChildId`, then the selector logic
 * might change the `activeChildId` if the user_name of a child was changed,
 * thus changing the sort order. In practice, this would be unlikely, since
 * various resolvers set the `activeChildId`, but it could happen in some cases.
 *
 * Verifying and normalizing the `activeChildId` in the store solves this, even
 * if it's not pretty.
 *
 * @see rawSortedFilteredChildren$
 */
const _selectRawSortedFilteredChildren = lruMemoize(
	compose(_selectRawSortedChildren, _selectRawChildren, _selectNonRetiredUsers),
)

/**
 * Checks whether we can update the current user based on the `schedule_ts`.
 */
const _canUpdateUser = (currentUser: RawUser, updatedUser: UserUpdate) => {
	const currTs = currentUser.schedule_ts || 0
	const updTs = updatedUser.schedule_ts || currTs

	return updTs >= currTs
}

const _updateUserState = (s: Draft<UserState>, update: UserUpdate) => {
	const currentUser = s.users.find((u) => update.user_id === u.user_id)

	// Only update if there is a user and the schedule ts is same or later than current.
	if (currentUser && _canUpdateUser(currentUser, update)) {
		forEach(update, (v, k) => {
			const u = currentUser as any

			if (!isEqual(u[k], v)) u[k] = v
		})
	}

	_validateActiveChildId(s)
}

const _getActiveChildId = ({ activeChildId, users }: UserState): Maybe<string> => {
	const children = _selectRawSortedFilteredChildren(users)

	// If there are no non-retired children, there's no active id
	if (isEmpty(children)) return null

	// If the active child id is associated with a valid child, use that.
	if (activeChildId && some(children, { user_id: activeChildId })) return activeChildId

	const firstChildWithDevices = find(children, (c) => !isEmpty(c.devices))

	// Return the first child with devices user id if there is one, otherwise,
	// just return the user id of the first child in the list.
	return firstChildWithDevices?.user_id || children[0]?.user_id
}

const _validateActiveChildId = (s: Draft<UserState>) => {
	s.activeChildId = _getActiveChildId(s)
}

////////// Selectors ///////////////////////////////////////////////////////////

const state$ = <T extends WithUserState>(s: T) => s.user
const rawUsers$ = /*@__PURE__*/ createSelector(state$, (s) => s.users)

export const activeChildId$ = /*@__PURE__*/ createSelector(state$, (s) => s.activeChildId)

/** Returns managed users from the full list of users */
export const rawChildren$ = /*@__PURE__*/ createSelector(rawUsers$, _selectRawChildren)

/** Raw children, sorted by user name */
export const rawSortedChildren$ = /*@__PURE__*/ createSelector(
	rawChildren$,
	_selectRawSortedChildren,
)

/** Returns all non-retired children from the list of users */
export const rawSortedFilteredChildren$ = /*@__PURE__*/ createSelector(rawSortedChildren$, (c) =>
	_selectNonRetiredUsers(c),
)

export const activeChild$ = /*@__PURE__*/ createSelector(
	activeChildId$,
	rawSortedFilteredChildren$,
	(id, c: RawManagedUser[]): Maybe<RawManagedUser> => find(c, { user_id: id as any }),
)

export const activeChildName$ = /*@__PURE__*/ createSelector(
	activeChild$,
	(c) => c?.user_name || '',
)
export const activeChildTz$ = /*@__PURE__*/ createSelector(activeChild$, (c) => c?.time_zone)
export const activeChildPrettyTz$ = /*@__PURE__*/ createSelector(activeChildTz$, (tz) =>
	(tz || '').replace(/_/g, ' '),
)
export const sortedFilteredChildIds$ = /*@__PURE__*/ createResultEqualSelector(
	rawSortedFilteredChildren$,
	(c): string[] => map(c, 'user_id'),
)
export const sortedChildIds$ = /*@__PURE__*/ createResultEqualSelector(
	rawSortedChildren$,
	(c): string[] => map(c, 'user_id'),
)
export const accountUser$ = /*@__PURE__*/ createSelector(rawUsers$, (u) => u.find(isAccountUser))
export const accountLocale$ = /*@__PURE__*/ createSelector(accountUser$, (u) => u?.locale)
export const accountTz$ = /*@__PURE__*/ createSelector(
	accountUser$,
	(p) => p?.time_zone || moment.tz.guess(),
)
export const hasAccountLocale$ = /*@__PURE__*/ createSelector(accountLocale$, notEmpty)
export const hasActiveChild$ = /*@__PURE__*/ createSelector(activeChildId$, Boolean)
export const hasChildren$ = /*@__PURE__*/ createSelector(rawSortedFilteredChildren$, notEmpty)
export const hasHadChildren$ = createSelector(rawChildren$, notEmpty)
export const isActiveChildAllowanceEnabled$ = /*@__PURE__*/ createSelector(activeChild$, (c) =>
	Boolean(c?.allowance_enabled),
)
export const isUsersInit$ = /*@__PURE__*/ createSelector(state$, (s) => s.isInit)
