import { concatLgStr } from '@op/util'
import has from 'lodash/has.js'

export type BaseAvatar = {
	readonly defaultColorIdx?: number
	readonly defaultImgIdx?: number
	readonly image?: string
}

export type Avatar = WithRequired<BaseAvatar, 'image'>
export type DefaultAvatar = WithRequired<BaseAvatar, 'defaultColorIdx' | 'defaultImgIdx'>
export type AnyAvatar = Avatar | DefaultAvatar

type AvatarType<T extends BaseAvatar> = T & Avatar
type DefaultAvatarType<T extends BaseAvatar> = T & DefaultAvatar

export const isAvatar = <T extends BaseAvatar>(av: T): av is AvatarType<T> => has(av, 'image')
export const isDefaultAvatar = <T extends BaseAvatar>(av: T): av is DefaultAvatarType<T> =>
	has(av, 'defaultColorIdx') && has(av, 'defaultImgIdx')

export const TOTAL_DEFAULT_AVATAR_IMGS = 4
export const TOTAL_DEFAULT_AVATAR_STYLES = 20

/**
 * Generates a predictable set of unique img + style combinations. This will
 * produce the output combinations:
 *
 * | 1,1 | 2,2 | 3,3 | 4,4 |
 * | 1,2 | 2,3 | 3,4 | 4,1 |
 * | 1,3 | 2,4 | 3,1 | 4,2 |
 * | 1,4 | 2,1 | 3,2 | 4,3 |
 *
 * NOTE: This algorithm only works when TOTAL_DEFAULT_AVATAR_IMGS and
 *  TOTAL_DEFAULT_AVATAR_STYLES are exactly the same. If either are different,
 *  a new, more complicated algorithm would be required, and that's why I made
 *  them `const` and added this note.
 */
export const generateDefaultAvatars = (): DefaultAvatar[] => {
	const avatars: DefaultAvatar[] = []

	for (let i = 0; i < TOTAL_DEFAULT_AVATAR_IMGS; ++i) {
		for (let j = 0; j < TOTAL_DEFAULT_AVATAR_STYLES; ++j) {
			avatars.push({
				defaultColorIdx: (i + j) % TOTAL_DEFAULT_AVATAR_STYLES,
				defaultImgIdx: j % TOTAL_DEFAULT_AVATAR_IMGS,
			})
		}
	}

	return avatars
}

export const DEFAULT_AVATARS = /*@__PURE__*/ generateDefaultAvatars()
export const DATA_URI_REGEX = /^data:([^;]+);base64,/
export const DATA_URI_TAG = 'data:image/jpeg;base64,'
export const DEFAULT_IMG_QUALITY = 0.8
export const DEFAULT_IMG_TYPE = 'image/jpeg'
export const MAX_IMG_SIZE = 1024
export const NEWLINE_REGEX = /[\n\r]+/g

export const normalizeDataURI = (data: string) =>
	concatLgStr(DATA_URI_TAG, normalizeDataURIReqData(data))

export const normalizeDataURIReqData = (data: string): string =>
	data.replace(DATA_URI_REGEX, '').replace(NEWLINE_REGEX, '')
