import { curry } from '@eturi/util'
import capitalize from 'lodash/capitalize.js'
import { FeatureMinAppVersion } from './FeatureInfo.js'

export type DeviceOS = 'android' | 'iOS' | 'Unknown'

type WithDeviceOS<T extends DeviceOS> = {
	readonly os: T
}

export type DeviceType = ValueUnion<typeof DeviceType>
export const DeviceType = {
	Account: 1,
	Managed: 10,
} as const

export type IOS_SUPERVISED_TYPE = typeof IOS_SUPERVISED
export const IOS_SUPERVISED = 'ios-supervised'

export type ChildAppBundle = ValueUnion<typeof ChildAppBundle>
export const ChildAppBundle = {
	ANDROID: 'com.eturi.ourpactjr',
	IOS: 'com.parentsware.ourpact.childapp',
} as const

export type RawDevice = {
	readonly account_id: string
	readonly app: Maybe<string>
	readonly app_version: Maybe<string>
	// readonly busy_count: null
	// readonly cert_issuer: null
	// readonly channels: null
	readonly attributes: Maybe<DeviceAttributes>
	readonly command_state: Maybe<string>
	readonly command_state_ts: Maybe<number>
	readonly created: number
	// readonly current_command_def_ts: null
	readonly current_command_id: Maybe<string>
	readonly current_command_ts: Maybe<number>
	readonly delivered_command_id: Maybe<string>
	readonly delivered_command_ts: Maybe<number>
	readonly device_id: string
	readonly device_location_active: Maybe<boolean>
	readonly device_location_ts: Maybe<number>
	readonly display_name: Maybe<string>
	readonly display_state: Maybe<string>
	readonly error_count: Maybe<number>
	readonly fcm_token: Maybe<string>
	// readonly imei: Maybe<string>
	// readonly installation_id: Maybe<string>
	readonly last_activity: Maybe<number>
	// readonly last_scheduled_id: Maybe<string>
	readonly last_seen: Maybe<number>
	readonly make: Maybe<string>
	readonly management_level: Maybe<IOS_SUPERVISED_TYPE>
	readonly management_removed: Maybe<boolean>
	// readonly mdm_organization: Maybe<string>
	// readonly mdm_token: Maybe<string>
	// readonly meid: Maybe<string>
	readonly model: Maybe<string>
	readonly model_name: Maybe<string>
	readonly notification_count: Maybe<number>
	readonly notification_ts: Maybe<number>
	// Suppressing eslint, b/c the union is not for type enforcement, but is
	// instead informational. This will usually be 'android', 'iOS', '', or null
	//eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
	readonly os: Maybe<DeviceOS | string>
	readonly os_version: Maybe<string>
	readonly pair_id: Maybe<string>
	readonly retired: Maybe<number>
	readonly schedule_ts: Maybe<number>
	// readonly serial: null
	readonly suspended: Maybe<boolean>
	readonly system_components: Maybe<ChildAppBundle>
	readonly time_zone: Maybe<string>
	// readonly topic: Maybe<string>
	readonly type: DeviceType
	readonly udid: Maybe<string>
	// readonly unlock_token: null
	readonly users: Maybe<string[]>
	readonly version: Maybe<string>
}

export type Device = RawDevice & {
	readonly app_version: string
	readonly device_location_active: boolean
	readonly displayName: string
	readonly error_count: number
	readonly make: string
	readonly management_removed: boolean
	readonly model: string
	readonly os: string
	readonly suspended: boolean
	readonly users: string[]
}

export type NewAccountDevice = {
	readonly app: string
	readonly app_version: string
	readonly fcm_token?: Maybe<string>
	readonly make: string
	readonly model: string
	readonly os: string
	readonly os_version: string
	readonly time_zone: string
	readonly type: DeviceType
	readonly udid?: string
	readonly users?: string[]
	readonly version: string
}

export type LoginDevice = NewAccountDevice & {
	readonly device_id?: string
}

export type DeviceAttributes = {
	readonly battery_level?: number
	readonly battery_level_ts?: number
}

export const WEB_PARENT_APP_NAME = 'WebApp-Parent'

export type DeviceOSAndroid<T> = T & WithDeviceOS<'android'>
export type DeviceOSIOS<T> = T & WithDeviceOS<'iOS'>
export type DeviceSupervised<T> = T & { readonly management_level: IOS_SUPERVISED_TYPE }
export type DeviceTypeAccount<T> = T & { readonly type: typeof DeviceType.Account }
export type DeviceTypeManaged<T> = T & {
	readonly os: DeviceOS
	readonly type: typeof DeviceType.Managed
}
export type DeviceWithIOSChildApp<T> = T & { readonly system_components: typeof ChildAppBundle.IOS }

export type AccountDevice = DeviceTypeAccount<Device>
export type ManagedDevice = DeviceTypeManaged<Device>
export type RawAccountDevice = DeviceTypeAccount<RawDevice>
export type RawManagedDevice = DeviceTypeManaged<RawDevice>

export const isAccountDevice = <T extends RawDevice>(d: T): d is DeviceTypeAccount<T> =>
	d.type === DeviceType.Account
export const isManagedDevice = <T extends RawDevice>(d: T): d is DeviceTypeManaged<T> =>
	d.type === DeviceType.Managed

const osAndroidLower = /*@__PURE__*/ 'android'
const osIOSLower = /*@__PURE__*/ 'ios'

export const isAndroidDevice = <T extends Pick<RawDevice, 'os'>>(
	d: T,
): d is T & WithDeviceOS<'android'> => (d.os || '').toLowerCase() === osAndroidLower

export const isIOSDevice = <T extends Pick<RawDevice, 'os'>>(d: T): d is T & WithDeviceOS<'iOS'> =>
	(d.os || '').toLowerCase() === osIOSLower

export const isSupervisedDevice = <T extends RawManagedDevice>(d: T): d is DeviceSupervised<T> =>
	d.management_level === IOS_SUPERVISED

export const hasIOSChildApp = <T extends DeviceOSIOS<RawManagedDevice>>(
	d: T,
): d is DeviceWithIOSChildApp<T> => d.system_components === ChildAppBundle.IOS

export const hasPremCapableIOSDevice = (d: DeviceOSIOS<ManagedDevice>, requireChildApp: boolean) =>
	isSupervisedDevice(d) && (!requireChildApp || hasIOSChildApp(d))

export const isFeatureCapableDevice =
	(minFeatVer: number, reqIOSChildApp: boolean) =>
	<T extends ManagedDevice>(d: T) =>
		isAndroidDevice(d) ? !isOutdatedAndroidDevice(minFeatVer, d)
		: isIOSDevice(d) ? hasPremCapableIOSDevice(d, reqIOSChildApp)
		: false

//export const isAppRulesCapableDevice = /*@__PURE__*/ isFeatureCapableDevice(
//	FeatureMinAppVersion.APP_RULES,
//	false,
//)
export const isGeoCapableDevice = /*@__PURE__*/ isFeatureCapableDevice(
	FeatureMinAppVersion.GEO,
	true,
)

export const isOutdatedAndroidDevice = /*@__PURE__*/ curry(
	(minFeatVer: number, d: DeviceOSAndroid<ManagedDevice>) =>
		Number.parseFloat(d.app_version) < minFeatVer,
)

export const isVewCapableDevice = (minFeatVer: number, minOSVer: number, d: ManagedDevice) =>
	hasMinOSVer(minOSVer, d) && hasMinFeatVer(minFeatVer, d)

export const hasMinOSVer = (minOSVer: number, d: ManagedDevice) =>
	Number.parseFloat(toMajorMinorVersion(d.os_version)) >= minOSVer

export const hasMinFeatVer = (minFeatVer: number, d: ManagedDevice) =>
	Number.parseFloat(d.app_version) >= minFeatVer

export const toMajorMinorVersion = (v: Maybe<string>): string => {
	if (!v) return ''

	const splitVersion = v.split('.')
	// Handles just major version i.e. 1
	if (splitVersion.length === 1) return v

	return splitVersion[0] + '.' + splitVersion[1]
}

export const normalizeDeviceOSStr = <T extends Pick<RawDevice, 'os'>>(d: T): string =>
	isAndroidDevice(d) ? 'android'
	: isIOSDevice(d) ? 'iOS'
	: d.os || 'Unknown'

export const getNormalizedModelName = <T extends RawDevice>(d: T): string =>
	d.model_name || d.display_name || d.model || 'Unknown'

export const getDeviceDisplayName = (d: RawDevice): string => {
	if (d.display_name) return d.display_name

	const make = capitalize(d.make || 'Unknown')
	const model = getNormalizedModelName(d)

	return isIOSDevice(d) ? `${make} ${model}` : model
}

export const mapRawToDevice = <T extends RawDevice>(raw: T): Device & T => {
	const make = capitalize(raw.make || 'Unknown')
	const model = getNormalizedModelName(raw)
	const os = normalizeDeviceOSStr(raw)

	return {
		...raw,
		app_version: raw.app_version || '',
		device_location_active: Boolean(raw.device_location_active),
		displayName: getDeviceDisplayName(raw),
		error_count: raw.error_count || 0,
		make,
		management_removed: Boolean(raw.management_removed),
		model,
		os,
		suspended: Boolean(raw.suspended),
		users: raw.users || [],
	}
}
