import type { AllowanceState } from './Allowance.js'
import type { AnyAvatar } from './Avatar.js'

export type UserTypes = ValueUnion<typeof UserTypes>
export const UserTypes = {
	Account: 1,
	Managed: 10,
} as const

type RawUserSettings = {
	readonly avatar_idx?: number // avatar img idx
	// readonly color_rgb?: string not used but server can pass this if needed
	// NOTE: This is field is only referred to as `slot` in the server model. When we use it in
	//  application code, we refer to as `colorIdx` or similar, because that's what it really is.
	readonly slot?: number // avatar color idx
}

export type ManagedUserSettings = RawUserSettings & {
	readonly avatar_idx: number
	readonly slot: number
}

/**
 * Only a few of these can be updated via PUT /user e.g. time_zone, birthday.
 * See partial types below that are used for updates
 */
export type RawUser = {
	readonly account_id: string
	readonly allowance: Maybe<number[]>
	readonly allowance_enabled: Maybe<boolean>
	readonly allowance_state: Maybe<AllowanceState>
	readonly birthday: Maybe<string>
	readonly created: number
	readonly devices: Maybe<string[]>
	readonly email: Maybe<string>
	readonly gender: Maybe<string>
	readonly img_modified: Maybe<number>
	readonly img_url: Maybe<string>
	readonly last_activity: Maybe<number>
	readonly locale: Maybe<string>
	readonly retired: Maybe<number>
	readonly schedule_ts: Maybe<number>
	// NOTE: This is only Maybe b/c of older clients w/ outdated models
	readonly settings: Maybe<RawUserSettings>
	readonly time_zone: Maybe<string>
	readonly type: UserTypes
	readonly user_id: string
	readonly user_location_active: Maybe<boolean>
	readonly user_name: Maybe<string>
	/**
	 * TODO: The `view_enabled` field in the `User` object is now an accurate representation of
	 *  whether Vew is enabled for the user, according to Dr. Mike. However, given the structure of
	 *  how we do our fetches and store our models, it's tricky to actually use this. Though the
	 *  field represents whether Vew is both enabled on the account and in the VewUserConfig, we have
	 *  to still call the same `update_user_config` endpoint to set the value. This means that if
	 *  change `isVewEnabledForActive$` selector to point its source of truth to the User field, we
	 *  have to always make sure to keep the user up-to-date when we modify that value.
	 *  Additionally, in my testing, there seems to be a brief period after enabling Vew where the
	 *  BlockStatus hasn't updated its `view_status` field properly. This may be unrelated, but
	 *  speaks to the async nature of these field calculations. Specifically, it will briefly show
	 *  "View broadcasting" because the if-statement falls through.
	 *  Ideally we will use a GraphQL-style of fetching in the future that can return composites.
	 *  This will allow to refactor out models to better reflect how they are used, and optimize
	 *  these sorts of issues. For now, I'll leave the stuff that polls on VewUserConfig.
	 */
	// readonly view_enabled: Maybe<boolean>
}

type WithUserType<T extends UserTypes> = {
	readonly type: T
}

export type RawManagedUser = RawUser & WithUserType<typeof UserTypes.Managed>

export type ManagedUser = RawManagedUser & {
	readonly avatar: AnyAvatar
	readonly settings: ManagedUserSettings
	readonly user_name: string
}

export type AccountUser = RawUser & {
	readonly email: string
	readonly type: typeof UserTypes.Account
}

export type AccountUserType<T extends RawUser> = T & {
	readonly email: string
	readonly type: typeof UserTypes.Account
}
export type ManagedUserType<T extends RawUser> = T & WithUserType<typeof UserTypes.Managed>

export const isAccountUser = <T extends RawUser>(u: T): u is AccountUserType<T> =>
	u.type === UserTypes.Account
export const isManagedUser = <T extends RawUser>(u: T): u is ManagedUserType<T> =>
	u.type === UserTypes.Managed

// Update user partial (requires user_id)
export type UserUpdatePartial<K extends keyof RawUser> = PickOnly<RawUser, K | 'user_id'>

export type CreateUserBody = {
	readonly time_zone: string
	readonly type: typeof UserTypes.Managed
	readonly user_name: string
}

type AllowanceEnableBody = UserUpdatePartial<'allowance_enabled'>
type AllowanceUpdateBody = UserUpdatePartial<'allowance'>
type GeoEnableBody = UserUpdatePartial<'user_location_active'>
type UserLocaleBody = UserUpdatePartial<'locale'>

type UserProfileFields = 'birthday' | 'gender' | 'time_zone' | 'user_name' | 'user_id'

// Pick only raw user fields, and partially pick settings. Ideally, we do diff + partial PUT
// at some point.
type PickUserProfileWithSlot = PickOnly<Omit<RawUser, 'settings'>, UserProfileFields> & {
	readonly settings?: PickOnly<RawUserSettings, 'slot'>
}

export type UserUpdateBody =
	| AllowanceEnableBody
	| AllowanceUpdateBody
	| GeoEnableBody
	| UserLocaleBody
	// Make sure updateUser gets only the partial though
	| PickUserProfileWithSlot
