import { notEmpty } from '@eturi/util'
import { createSelector } from '@reduxjs/toolkit'
import orderBy from 'lodash/orderBy.js'
import type { UserRuleState } from '../reducers/index.js'
import {
	accountAccess$,
	activeChildId$,
	createUserRuleState,
	ruleState$,
} from '../reducers/index.js'
import type { Override, Schedule } from '../types/index.js'
import {
	isOverrideRule,
	isScheduleRule,
	mapRawToOverride,
	rawRuleToSchedule,
} from '../types/index.js'

export const activeUserRuleState$ = /*@__PURE__*/ createSelector(
	activeChildId$,
	ruleState$,
	(id, s): UserRuleState => s[id || ''] || createUserRuleState(),
)

export const activeUserRawRules$ = /*@__PURE__*/ createSelector(
	activeUserRuleState$,
	(s) => s.rules,
)

export const activeUserOverrideRule$ = /*@__PURE__*/ createSelector(
	activeUserRawRules$,
	(rules): Maybe<Override> => {
		const overrideRule = rules.find(isOverrideRule)

		return overrideRule ? mapRawToOverride(overrideRule) : null
	},
)

/**
 * Get schedules by filtering schedule rules, mapping raw to schedule, and
 * sorting by rule_name
 */
export const activeUserSchedules$ = /*@__PURE__*/ createSelector(
	activeUserRawRules$,
	(rules): Schedule[] => {
		const scheduleRules: Schedule[] = []

		for (const rule of rules) {
			if (isScheduleRule(rule)) scheduleRules.push(rawRuleToSchedule(rule))
		}

		return orderBy(scheduleRules, (s) => s.rule_name.toLowerCase())
	},
)

export const activeUserHasSchedules$ = /*@__PURE__*/ createSelector(activeUserSchedules$, notEmpty)

export const activeUserHasMaxSchedules$ = /*@__PURE__*/ createSelector(
	accountAccess$,
	activeUserSchedules$,
	(access, schedules) => schedules.length >= access.schedules_per_child,
)

export const hasActiveUserOverrideRule$ = /*@__PURE__*/ createSelector(
	activeUserOverrideRule$,
	Boolean,
)
