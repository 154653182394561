import { createSelector } from '@reduxjs/toolkit'
import find from 'lodash/find.js'
import sortBy from 'lodash/sortBy.js'
import {
	activeChild$,
	activeChildId$,
	avatarState$,
	isActiveChildAllowanceEnabled$,
	rawSortedFilteredChildren$,
} from '../reducers/index.js'
import type { DefaultAvatar, ManagedUser, ManagedUserSettings } from '../types/index.js'
import { DEFAULT_AVATARS } from '../types/index.js'
import { isMinPremAccess$ } from './access.js'

/**
 * Maps all children to decorated, ManagedUser, correctly assigning avatars and
 * normalizing user_name. Avatar algorithm is as follows:
 *
 * - Filter users to non-retired children
 * - Sort by created date
 * - Assign defaults in order by mod index to children without img_url. This
 *   ensures the same default avatar is used for the same child.
 */
export const children$ = /*@__PURE__*/ createSelector(
	avatarState$,
	rawSortedFilteredChildren$,
	(avatarState, rawSortedFilteredChildren): ManagedUser[] => {
		const rawChildrenSortedByCreated = sortBy(rawSortedFilteredChildren, 'created')

		return rawSortedFilteredChildren.map((c) => {
			const userAvatarState = avatarState[c.user_id] || { isInit: false }
			// NOTE: rawSortedFilteredChildren and cByCreated have the same filtering but different
			//  sorting, so they will always have the same length (and therefore we
			//  should never have an indexOf miss

			const defaultAvatar =
				DEFAULT_AVATARS[rawChildrenSortedByCreated.indexOf(c) % DEFAULT_AVATARS.length]

			// Children will have these avatar values in their settings, however
			// if for some reason they don't we normalize it the values using
			// idx based values.
			const normalizedSettings: ManagedUserSettings = {
				avatar_idx:
					Number.isInteger(c.settings?.avatar_idx) ?
						c.settings.avatar_idx
					:	defaultAvatar.defaultImgIdx,
				slot: Number.isInteger(c.settings?.slot) ? c.settings.slot : defaultAvatar.defaultColorIdx,
			}

			const avatar =
				userAvatarState.isInit && userAvatarState.avatar ?
					userAvatarState.avatar
				:	({
						defaultColorIdx: normalizedSettings.slot,
						defaultImgIdx: normalizedSettings.avatar_idx,
					} as DefaultAvatar)

			return {
				...c,
				avatar,
				settings: normalizedSettings,
				user_name: c.user_name || '',
			}
		})
	},
)

export const activeChildDeviceIds$ = /*@__PURE__*/ createSelector(
	activeChild$,
	(c) => c?.devices || [],
)

export const activeManagedChild$ = /*@__PURE__*/ createSelector(
	children$,
	activeChildId$,
	(children, activeChildId): Maybe<ManagedUser> => find(children, { user_id: activeChildId! }),
)

export const isSingleChildAccount$ = /*@__PURE__*/ createSelector(children$, (c) => c.length <= 1)

export const canActiveChildAccessAllowance$ = /*@__PURE__*/ createSelector(
	isActiveChildAllowanceEnabled$,
	isMinPremAccess$,
	(isActiveChildEnabled, isMinPrem) => Boolean(isActiveChildEnabled && isMinPrem),
)
