import { curry, selectUnaryEvery, selectUnarySome } from '@eturi/util'
import { notIdentity } from '@op/util'
import { createSelector } from '@reduxjs/toolkit'
import map from 'lodash/map.js'
import reduce from 'lodash/reduce.js'
import { accountAccess$ } from '../reducers/access.slice.js'
import { account$, paymentSource$, rawSKUDefs$, tempTier$ } from '../reducers/index.js'
import {
	getSKUForPlatformTier,
	isPlatformSKU,
	mapRawToSKUDef,
	mapSKUDefToFullName,
	mapSKUDefToName,
	mapTierToFullName,
	mapToHighestSKU,
	paymentSourceToInfo,
} from '../types/index.js'
import type {
	AccountSKU,
	AccountSubs,
	PaidTier,
	SKUDef,
	SubSource,
	TierDef,
} from '../types/index.js'

/**
 * Whether user has a particular access state. If they have a tempTier then we
 * use that, otherwise, we use the effective sku tier def. Note that only in
 * cancellation do users retain the access state of the previous SKU since
 * payment providers do pro-rating and whatnot.
 */
const _hasAccessTier = curry(
	(accessTier: TierDef, tempTier: Maybe<PaidTier>, effectiveSKU: AccountSKU) =>
		accessTier === (tempTier ?? effectiveSKU.tier_def),
)
const _isTierDef = /*@__PURE__*/ curry((def: TierDef, sku: AccountSKU) => sku.tier_def === def)
const _hasTierDef = /*@__PURE__*/ curry((def: TierDef, skus: AccountSKU[]) =>
	skus.some(_isTierDef(def)),
)
const _isSubSource = /*@__PURE__*/ curry((src: SubSource, sku: AccountSKU) => sku.source === src)

/**
 * Returns a function that gets a Stripe SKU based on TierLevels. These are
 * static and is guaranteed to exist as long as access call has been made
 * successfully.
 */
const _stripeSKUForDef =
	(tierDef: TierDef) =>
	(defs: SKUDef[]): SKUDef =>
		getSKUForPlatformTier(defs, tierDef, 'stripe')!

/** Pluck AccountSubscriptions from AccountInfo */
export const accountSubs$ = /*@__PURE__*/ createSelector(
	account$,
	(info): AccountSubs => info?.skus || {},
)

export const activeAccountSubs$ = /*@__PURE__*/ createSelector(
	accountSubs$,
	(subs): AccountSubs =>
		reduce(
			subs,
			(activeSubs: Writable<AccountSubs>, sub, skuName) => {
				if (!sub.cancelled) activeSubs[skuName] = sub

				return activeSubs
			},
			{},
		),
)

/** Takes in raw sku defs and decorates them */
export const skuDefs$ = /*@__PURE__*/ createSelector(rawSKUDefs$, (rawDefs): SKUDef[] =>
	map(rawDefs, mapRawToSKUDef),
)

/**
 * This iterates all the subscriptions and then unions them with the associated
 * sku definition, filtering out any misses.
 */
export const accountSKUs$ = /*@__PURE__*/ createSelector(
	accountSubs$,
	skuDefs$,
	(subs, defs): AccountSKU[] => {
		const skus: AccountSKU[] = []

		for (const skuName in subs) {
			const sub = subs[skuName]
			let sku: Maybe<SKUDef>

			if ((sku = defs.find((s) => s.sku === skuName))) skus.push({ ...sku, ...sub })
		}

		return skus
	},
)

export const stripeSKUDefs$ = /*@__PURE__*/ createSelector(skuDefs$, (defs) =>
	defs.filter((d) => isPlatformSKU(d, 'stripe')),
)

/**
 * NOTE: The "Effective SKU" is the current SKU the account tier is being enforced
 *  at. This is usually the same as the "Subscribed" SKU, except in cases where
 *  a user cancels a higher SKU. They will then have access to the higher SKU
 *  until it expires, even though it's cancelled.
 */
export const effectiveSKU$ = /*@__PURE__*/ createSelector(accountSKUs$, (s) =>
	mapToHighestSKU(s, false),
)

/**
 * This is the display name of the current SKU. It is either based on either
 * the temp tier (if it exists) or the effective sku.
 */
export const skuDisplayFullName$ = /*@__PURE__*/ createSelector(
	effectiveSKU$,
	tempTier$,
	(effectiveSKU, tempTier) =>
		tempTier ? mapTierToFullName(tempTier) : mapSKUDefToFullName(effectiveSKU),
)

// Returns on demand limit for effective (currently enforced) sku, -1 indicated
// unlimited on demands
//export const onDemandLimit$ = /*@__PURE__*/ createSelector(
//	effectiveSKU$,
//	(sku) => sku.vew_on_demand_per_month,
//)

/**
 * NOTE: The nextSKU is the current SKU the account is subscribed to, and that
 *  will be billed (if applicable). This means filtering out cancelled skus.
 */
export const nextSKU$ = /*@__PURE__*/ createSelector(accountSKUs$, (s) => mapToHighestSKU(s, true))

export const isAppleSub$ = /*@__PURE__*/ createSelector(nextSKU$, _isSubSource('apple'))
export const isCancelledSub$ = /*@__PURE__*/ createSelector(effectiveSKU$, (s) => s.cancelled)
export const isFreeAccess$ = /*@__PURE__*/ createSelector(
	tempTier$,
	effectiveSKU$,
	_hasAccessTier('free'),
)
export const isGoogleSub$ = /*@__PURE__*/ createSelector(nextSKU$, _isSubSource('google'))
//export const isGrandfatheredAccess$ = /*@__PURE__*/ createSelector(
//	tempTier$,
//	effectiveSKU$,
//	_hasAccessTier('grandfathered'),
//)
export const isNextSKUPlus$ = /*@__PURE__*/ createSelector(
	tempTier$,
	nextSKU$,
	/*@__PURE__*/ _hasAccessTier('plus'),
)
export const isNextSKUPrem$ = /*@__PURE__*/ createSelector(
	tempTier$,
	nextSKU$,
	/*@__PURE__*/ _hasAccessTier('premium'),
)
export const isNextSKUPremPlus$ = /*@__PURE__*/ createSelector(
	tempTier$,
	nextSKU$,
	/*@__PURE__*/ _hasAccessTier('premium_plus'),
)
export const isPaidSub$ = /*@__PURE__*/ createSelector(nextSKU$, (s) => s.price_usd > 0)
//export const isPlusAccess$ = /*@__PURE__*/ createSelector(
//	tempTier$,
//	effectiveSKU$,
//	_hasAccessTier('plus'),
//)
export const isPremAccess$ = /*@__PURE__*/ createSelector(
	tempTier$,
	effectiveSKU$,
	/*@__PURE__*/ _hasAccessTier('premium'),
)
export const isPremPlusAccess$ = /*@__PURE__*/ createSelector(
	tempTier$,
	effectiveSKU$,
	/*@__PURE__*/ _hasAccessTier('premium_plus'),
)
export const isStripeSub$ = /*@__PURE__*/ createSelector(nextSKU$, _isSubSource('stripe'))
// We have a temp tier both if one is in the state, and if it's not the same
//  as the effective SKU. If it's the same, it's no longer "temp".
export const isTempTier$ = /*@__PURE__*/ createSelector(tempTier$, effectiveSKU$, (t, s) =>
	Boolean(t && !_isTierDef(t, s)),
)
export const nextSKUFullName$ = /*@__PURE__*/ createSelector(nextSKU$, mapSKUDefToFullName)
export const nextSKUId$ = /*@__PURE__*/ createSelector(nextSKU$, (s) => s.sku)
export const nextSKUName$ = /*@__PURE__*/ createSelector(nextSKU$, mapSKUDefToName)
export const nextSKUSource$ = /*@__PURE__*/ createSelector(nextSKU$, (s) => s.source)
export const nextSubCycle$ = /*@__PURE__*/ createSelector(nextSKU$, (s) => s.billing_cycle)
export const paymentInfo$ = /*@__PURE__*/ createSelector(paymentSource$, paymentSourceToInfo)
export const stripeSKUPrem$ = /*@__PURE__*/ createSelector(skuDefs$, _stripeSKUForDef('premium'))
export const stripeSKUPremPlus$ = /*@__PURE__*/ createSelector(
	skuDefs$,
	_stripeSKUForDef('premium_plus'),
)
export const wasGrandfathered$ = /*@__PURE__*/ createSelector(
	accountSKUs$,
	_hasTierDef('grandfathered'),
)

export const isAnnualSub$ = /*@__PURE__*/ createSelector(nextSubCycle$, (s) => s === 'annual')

// Makes sure we have at least Prem Access, having Prem Plus should give us Prem
// access as well
export const isMinPremAccess$ = /*@__PURE__*/ createSelector(
	isPremAccess$,
	isPremPlusAccess$,
	selectUnarySome,
)

export const isPaidAccount$ = /*@__PURE__*/ createSelector(
	/*@__PURE__*/ createSelector(isNextSKUPlus$, isNextSKUPrem$, isNextSKUPremPlus$, selectUnarySome),
	isPaidSub$,
	selectUnaryEvery,
)

/**
 * Whether we have a Stripe sub that can be upgraded via POST /purchase. This
 * is in contract to having to create a new purchase. If the user is already
 * a Stripe customer, we can simply change their subscription, instead of
 * creating a brand new customer and sub.
 */
export const canChangeStripeSub$ = /*@__PURE__*/ createSelector(
	isStripeSub$,
	isPaidAccount$,
	selectUnaryEvery,
)

const notCancelledSub$ = /*@__PURE__*/ createSelector(isCancelledSub$, notIdentity)

/** Determines whether the current SKU can be cancelled */
export const canCancelSub$ = /*@__PURE__*/ createSelector(
	notCancelledSub$,
	isPaidAccount$,
	selectUnaryEvery,
)

/** Determines whether the current SKU is downgradable */
export const canDowngradeSub$ = /*@__PURE__*/ createSelector(
	/*@__PURE__*/ createSelector(isTempTier$, notIdentity),
	/*@__PURE__*/ createSelector(wasGrandfathered$, notIdentity),
	isNextSKUPremPlus$,
	notCancelledSub$,
	selectUnaryEvery,
)

/** Whether we're in a state that allows upgrading to plus */
export const canUpgradeToPlus$ = /*@__PURE__*/ createSelector(
	isCancelledSub$,
	isFreeAccess$,
	isTempTier$,
	(isCancelled, isFree, isTemp) => !isTemp && (isFree || isCancelled),
)

export const blocksRemaining$ = /*@__PURE__*/ createSelector(
	account$,
	accountAccess$,
	(account, access) => access.blocks_per_month - (account?.block_count || 0),
)

export const hasBlocksRemaining$ = /*@__PURE__*/ createSelector(
	blocksRemaining$,
	(remaining) => remaining > 0,
)
