import { add, duration } from '@eturi/date-util'
import { assertNotNullish, pick, selectUnaryEvery, setIfNotEqual } from '@eturi/util'
import { keysToBool, keysToInt, size, toParamsURL } from '@op/util'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { createSliceTransformer } from 'rtk-slice-transformer'
import { resetAction } from '../actions/index.js'
import { bindCreateAsyncThunkToState, unwrapThunk } from '../bindCreateAsyncThunkToState.js'
import type { HttpExtra } from '../http.js'
import type {
	Account,
	AccountDeletionRes,
	AccountDeletionStatusRes,
	ChangeCredentialsRes,
	ChangePwBody,
	DecryptPemPairFn,
	EmailVerificationStatusRes,
	EncryptPrivateKeyFn,
	GenerateKeyIdFn,
	GeneratePasswordHashFn,
	GeneratePemPairFn,
	GetPubSubTokenBody,
	InitState,
	KeyExchangeBody,
	KeyInfo,
	LoginBody,
	LoginDevice,
	LoginRes,
	PubSubToken,
	RegisterAccountKeyArgs,
	RegisterKeyRes,
	ResetPwRes,
	ResetPwStatus,
	RetrieveKeyRes,
	SThunk,
	SThunkState,
	TempKeyMap,
} from '../types/index.js'
import {
	APIErrorCode,
	createAPIError,
	createReqError,
	isAPIErrorType,
	ReqErrorCode,
} from '../types/index.js'
import { toBoolAction, toIntAction } from '../util/index.js'

export type AccountState = InitState & {
	readonly account: Maybe<Account>
	readonly accountId: Maybe<string>
	readonly deviceId: Maybe<string>
	readonly email: string
	readonly encryptedPrivate: Maybe<string>
	// NOTE: encryptedPrivateId is currently not used for anything, so I'm
	//  commenting so to make the code a bit less confusing.
	// readonly encryptedPrivateId: Maybe<string>
	readonly hasPasswordError: boolean
	readonly isLegacy: boolean
	readonly keys: TempKeyMap
	readonly parentSecret: Maybe<string>
	readonly parentSecretId: Maybe<string>
	readonly password: Maybe<string>
	readonly privateKeyPem: Maybe<string>
	readonly publicKeyPem: Maybe<string>
	readonly pubSubToken: Maybe<PubSubToken>
	readonly token: Maybe<string>
	readonly tokenTS: number
	readonly userId: Maybe<string>
	readonly version: number
}

export type WithAccountState = {
	readonly account: AccountState
}

const initialState: AccountState = {
	account: null,
	accountId: null,
	deviceId: null,
	email: '',
	encryptedPrivate: null,
	hasPasswordError: false,
	isInit: false,
	isLegacy: false,
	keys: {},
	parentSecret: null,
	parentSecretId: null,
	password: null,
	privateKeyPem: null,
	publicKeyPem: null,
	pubSubToken: null,
	token: null,
	tokenTS: 0,
	userId: null,
	version: 2,
}

export const accountSlice = /*@__PURE__*/ createSlice({
	name: 'account',
	initialState,
	reducers: {
		// NOTE: Currently only used for testing
		setAccount(s, { payload: account }: PayloadAction<Account>) {
			s.email = account.emails[0]
			setIfNotEqual(s, 'account', account)
		},

		setAccountEmail(s, a: PayloadAction<string>) {
			s.email = a.payload
		},

		setLegacy(s, a: PayloadAction<boolean>) {
			s.isLegacy = a.payload
		},

		setTempKeys(s, a: PayloadAction<TempKeyMap>) {
			// NOTE: We always merge the TempKeyMap, b/c we assume that updates are
			//  happening only when we need to decrypt a new one.
			// FIXME: This also never will remove temp keys. This needs to be addressed.
			s.keys = { ...s.keys, ...a.payload }
		},
	},
	extraReducers: (builder) =>
		builder
			.addCase(resetAction, () => initialState)
			.addCase(fetchAccount.fulfilled, (s, a) => {
				setIfNotEqual(s, 'account', a.payload)
				s.email = a.payload.emails[0]
				s.isInit = true
			})
			.addCase(fetchPubSubToken.fulfilled, (s, { payload: token }) => {
				if (token) setIfNotEqual(s, 'pubSubToken', token)
			})
			.addCase(doLogin.fulfilled, (s, a) => {
				s.isLegacy = a.meta.arg.isLegacy
			})
			.addCase(login.fulfilled, (s, { payload: [authenticatedAccount, device], meta }) => {
				const email = meta.arg.email

				const {
					parent_secret: parentSecret,
					parent_secret_id: parentSecretId,
					token,
					...account
				} = authenticatedAccount

				const nextState = {
					...s,
					account,
					accountId: account.account_id,
					email,
					isInit: true,
					parentSecret,
					parentSecretId,
					token,
					tokenTS: Date.now(),
				}

				// NOTE: We only set these fields if we have a device returned, else we
				//  would be clearing these fields for reAuth calls.
				if (device) {
					nextState.deviceId = device.device_id
					nextState.userId = device.users?.[0]
				}

				return nextState
			})
			.addMatcher(isAnyOf(setPassword.fulfilled, setEncryptedPrivate.fulfilled), (s, a) => {
				Object.assign(s, a.payload)
			}),
})

export const { setAccount, setAccountEmail, setLegacy, setTempKeys } = accountSlice.actions

// Merging rehydrated state requires handling the encryption state
export const createRehydrateAuthState =
	(decryptPrivateKey: DecryptPemPairFn) =>
	async (partial: Partial<AccountState>): Promise<AccountState> => {
		// If partial state is version 1, clear the `encryptedPrivate` and update the
		// version. This isn't strictly necessary, since, in V2, we plan to make
		// periodic calls to check the `encryptedPrivateId` for new keys. But this
		// does explicitly make sure a faulty `encryptedPrivate` is not used for
		// anything.
		const v1ToV2Obj = partial.version === 1 ? { encryptedPrivate: null, version: 2 } : {}
		const mergedState = {
			...initialState,
			...partial,
			...v1ToV2Obj,
			// Android Parent can partially rehydrate `parentSecret` but not include
			// `parentSecretId`. If this occurs, we need to assume the account is legacy
			isLegacy: Boolean(partial.isLegacy || (partial.parentSecret && !partial.parentSecretId)),
		}

		const partialEncState = await handleEncryptedState(decryptPrivateKey, mergedState)

		return { ...mergedState, ...partialEncState }
	}

const isSanitizeBoolAction = /*@__PURE__*/ isAnyOf(setAccountEmail)

const sanitizeAccount = (a: Account) => ({
	...pick(a, ['block_count', 'grant_count', 'last_activity', 'retired', 'skus']),
	...keysToInt(a, ['emails', 'retired_devices', 'retired_users']),
})

export const accountSliceTransformer = /*@__PURE__*/ createSliceTransformer(
	accountSlice,
	(s) => ({
		...s,
		account: sanitizeAccount(s.account!),
		...keysToBool(s, [
			'email',
			'encryptedPrivate',
			'parentSecret',
			'parentSecretId',
			'password',
			'privateKeyPem',
			'publicKeyPem',
			'pubSubToken',
			'token',
		]),
		keys: size(s.keys),
	}),
	(a) => {
		if (setAccount.match(a)) return { ...a, payload: sanitizeAccount(a.payload) }
		if (isSanitizeBoolAction(a)) return toBoolAction(a)
		if (setTempKeys.match(a)) return toIntAction(a)

		return a
	},
)

////////// Thunks //////////////////////////////////////////////////////////////

const createAsyncThunk = /*@__PURE__*/ bindCreateAsyncThunkToState<SThunkState>()

type AccountCryptoState = MPick<
	AccountState,
	'encryptedPrivate' | 'password' | 'privateKeyPem' | 'publicKeyPem'
>

/**
 * This function is used to decrypt the private key and obtain the
 * private / public PEM when the state affecting these fields changes.
 * @param decryptPemPair
 * @param state
 */
const handleEncryptedState = async (
	decryptPemPair: DecryptPemPairFn,
	state: AccountCryptoState,
) => {
	const { encryptedPrivate, password } = state
	let { privateKeyPem, publicKeyPem } = state

	if (encryptedPrivate && password && !privateKeyPem) {
		try {
			const pemPair = await decryptPemPair(encryptedPrivate, password)
			privateKeyPem = pemPair.privateKey
			publicKeyPem = pemPair.publicKey
		} catch (e) {
			console.error(e)
			return {
				encryptedPrivate,
				hasPasswordError: true,
				password: null,
				privateKeyPem: null,
				publicKeyPem: null,
			}
		}
	}

	return {
		encryptedPrivate,
		hasPasswordError: false,
		password,
		privateKeyPem,
		publicKeyPem,
	}
}

export type WithDecryptPrivateKey = {
	readonly decryptPemPair: DecryptPemPairFn
}

export type WithGenPwHash = {
	readonly generatePasswordHash: GeneratePasswordHashFn
}

export type AccountAuthArgs = WithDecryptPrivateKey & WithGenPwHash

export type AccountGenArgs = AccountAuthArgs & {
	readonly encryptPrivateKey: EncryptPrivateKeyFn
	readonly generateKeyId: GenerateKeyIdFn
	readonly generatePemPair: GeneratePemPairFn
}

type ChangePwArgs = AccountGenArgs & {
	readonly currentPw: string
	readonly isUpgrade?: boolean
	readonly newPw: string
}

export const changePw = /*@__PURE__*/ createAsyncThunk(
	'account/changePw',
	async (
		{ currentPw, isUpgrade = false, newPw, ...genArgs }: ChangePwArgs,
		{ dispatch, getState, extra: { http } },
	) => {
		const { decryptPemPair: decrypt } = genArgs
		// If the current password is not already in state, add it so that keyPair
		// is generated if it needs to be.
		await dispatch(setPassword({ decrypt, password: currentPw }))

		// First try to retrieve a key pair
		await unwrapThunk(dispatch(fetchEncryptedPrivate({ ...genArgs, force: 'soft' })))

		// Get the state after setting password and fetching encrypted private
		const state = getState()

		// If after setting the current password and fetching encrypted private we
		// end up with a password error, throw an invalid password error.
		if (hasPasswordError$(state)) {
			throw createAPIError(APIErrorCode.INVALID_PASSWORD)
		}

		const accountId = accountId$(state)
		const email = accountEmail$(state)
		const isLegacyAccount = isLegacyAccount$(state)
		const privateKeyPem = privateKeyPem$(state)
		const publicKeyPem = publicKeyPem$(state)
		const pemPair =
			privateKeyPem && publicKeyPem ? { privateKey: privateKeyPem, publicKey: publicKeyPem } : null

		const [acctkey, new_password, password] = await Promise.all([
			// If we have a key pair for this account, we need to generate a new acctkey
			pemPair ?
				getRegisterAccountKeyArgs(accountId!, newPw, {
					...genArgs,
					generatePemPair: () => Promise.resolve(pemPair),
				})
			:	Promise.resolve(null),
			// NOTE: We aren't auto-upgrading for this release. When we auto-upgrade in
			//  a future release, we'll remove this `isLegacyAccount && isUpgrade`
			//  check and go straight to password hashing for `newPassword`.
			isLegacyAccount && !isUpgrade ?
				Promise.resolve(newPw)
			:	genArgs.generatePasswordHash(newPw, email),
			isLegacyAccount ? Promise.resolve(currentPw) : genArgs.generatePasswordHash(currentPw, email),
		])

		const data: ChangePwBody = {
			login_obj: {
				...(acctkey ? { acctkey } : {}),
				email,
				new_password,
				password,
			},
		}

		const { status } = await dispatch(http.put<ChangeCredentialsRes>('/credentials', { data }))

		if (status !== 'password_updated') {
			throw new Error(`Bad status for PUT /credentials ${status}`)
		}

		// FIXME: Create actions to batch multi-dispatch to one?
		// FIXME: Async

		// If this is an upgrade we update to new authentication on pw change,
		// so we're not legacy.
		isUpgrade && dispatch(setLegacy(false))

		await dispatch(setPassword({ decrypt, password: newPw }))

		if (acctkey) {
			await dispatch(setEncryptedPrivate({ decrypt, key: acctkey.enc_priv }))
		}

		// Re-auth for new secret
		await dispatch(reAuth({ ...genArgs, password: newPw })).unwrap()

		// If were previously a legacy account and upgrading, register a new
		// account key
		if (isLegacyAccount && isUpgrade) {
			await dispatch(registerKey(genArgs)).unwrap()
		}
	},
)

export const fetchAccount = /*@__PURE__*/ createAsyncThunk(
	'account/fetch',
	async (extra: HttpExtra = {}, { dispatch, extra: { http } }) => {
		const account = await dispatch(http.get<Maybe<Account>>('/account', extra))

		assertNotNullish(account, 'Account')

		return account
	},
	{
		condition: (arg, api) => {
			if (!arg?.force && isAccountInit$(api.getState())) return false
		},
	},
)

type FetchEncryptedPrivateArg = HttpExtra & AccountGenArgs

let lastFetchEncTs = 0

export const fetchEncryptedPrivate = /*@__PURE__*/ createAsyncThunk(
	'key-exchange/fetch-encrypted-private',
	async (arg: FetchEncryptedPrivateArg, { dispatch }) => {
		let keyInfo: Maybe<KeyInfo> = await dispatch(retrieveKey(arg)).unwrap()
		keyInfo ||= await dispatch(registerKey(arg)).unwrap()

		assertNotNullish(keyInfo, 'KeyInfo')

		await dispatch(setEncryptedPrivate({ decrypt: arg.decryptPemPair, key: keyInfo.enc_priv }))
	},
	{
		condition: (arg, api) => {
			// NOTE: This needs to be throttled because of a possible race condition.
			//  Even though the get call to fetch encrypted is deduped, the registerKey call
			//  that happens when it returns null is not deduped. So we can end up with
			//  a race condition where we try to create keys at the same time causing some
			//  this call to fail
			if (Date.now() - lastFetchEncTs < duration(5, 's')) return false
			lastFetchEncTs = Date.now()

			const state = api.getState()
			// Don't try to retrieve key if we're a legacy account or already have one
			if (isLegacyAccount$(state) || (!arg?.force && hasEncryptedPrivate$(state))) return false
		},
	},
)

type FetchPubSubTokenArg = {
	readonly accountUserId: string
	readonly sessionDeviceId: string
}

export const fetchPubSubToken = /*@__PURE__*/ createAsyncThunk(
	'pubSub/fetch',
	async (
		{ accountUserId, sessionDeviceId }: FetchPubSubTokenArg,
		{ dispatch, extra: { http } },
	) => {
		const data: GetPubSubTokenBody = {
			device_id: sessionDeviceId,
			token_type: 'parent_pubsub_token',
			user_id: accountUserId,
		}

		return await dispatch(http.get<Maybe<PubSubToken>>(toParamsURL('/access_token', data)))
	},
)

export const forgotPw = /*@__PURE__*/ createAsyncThunk(
	'account/forgotPw',
	async (email: string, { dispatch, extra: { http } }) => {
		const { status } = await dispatch(
			http.post<ResetPwRes>('/resetpw', { data: { email }, isUnauthenticated: true }),
		)

		if (status !== 'email_sent') {
			throw new Error(`Invalid forgot pw status ${status}`)
		}
	},
)

export const getEmailVerificationStatus = /*@__PURE__*/ createAsyncThunk(
	'email-verify/status',
	async (_, { dispatch, extra: { http } }) =>
		dispatch(http.get<EmailVerificationStatusRes>('/account_verification')),
)

/**
 * Generates args for changing the password / encrypted private key. If the
 * current key pair is passed, it is re-encrypted (presumably using a new
 * password, for password change). Otherwise, a new key pair is generated.
 */
export const getRegisterAccountKeyArgs = async (
	accountId: string,
	password: string,
	{ encryptPrivateKey, generateKeyId, generatePemPair, generatePasswordHash }: AccountGenArgs,
): Promise<RegisterAccountKeyArgs> => {
	const [secret, { privateKey, publicKey }] = await Promise.all([
		generatePasswordHash(password, accountId),
		generatePemPair(),
	])

	const [enc_priv, key_id] = await Promise.all([
		encryptPrivateKey(privateKey, password),
		generateKeyId(publicKey),
	])

	return {
		enc_priv,
		key: publicKey,
		key_id,
		key_type: 'rsa',
		secret,
	}
}

type LoginArgs = AccountAuthArgs & {
	readonly device?: LoginDevice
	readonly email: string
	readonly password: string
}

export const login = /*@__PURE__*/ createAsyncThunk(
	'account/login',
	async (args: LoginArgs, { dispatch, getState }) => {
		const isLegacyArgs = (isLegacy: boolean) => ({ ...args, isLegacy })

		// If we already know the account is legacy, don't try to do the new auth
		if (isLegacyAccount$(getState())) {
			return await dispatch(doLogin(isLegacyArgs(true))).unwrap()
		}

		try {
			return await dispatch(doLogin(isLegacyArgs(false))).unwrap()
		} catch (e) {
			// Re-throw if this isn't an auth scheme error. We only do the try / catch
			//  here to handle legacy authentication. All other error types need to be
			//  handled where this action is invoked.
			if (!isAPIErrorType(e, APIErrorCode.UNSUPPORTED_AUTH_SCHEME)) throw e
		}

		// If we get this far, it's a legacy account, so try that.
		return await dispatch(doLogin(isLegacyArgs(true))).unwrap()
	},
)

/** Does the actual login call, taking in `isLegacy` to determine how */
const doLogin = /*@__PURE__*/ createAsyncThunk(
	'account/doLogin',
	async (
		{
			decryptPemPair,
			device: loginDevice,
			email,
			generatePasswordHash,
			isLegacy,
			password,
		}: LoginArgs & { readonly isLegacy: boolean },
		{ dispatch, extra: { http } },
	) => {
		const data: LoginBody = {
			device: loginDevice,
			login_obj: { email, password },
		}

		if (!isLegacy) {
			data.login_obj.password = await generatePasswordHash(password, email)
		}

		const ret = await dispatch(http.post<LoginRes>('/login', { data, isUnauthenticated: true }))

		assertNotNullish(ret[0], 'AuthenticatedAccount')

		// NOTE: We need to set password first, because clients may way for
		//  `isAuthenticated` in order to `fetchEncryptedPrivate`, and if password
		//  is not set, we'll end up trying to `registerKey` instead of `retrieveKey`
		await dispatch(setPassword({ decrypt: decryptPemPair, password }))

		return ret
	},
)

type ReAuthArg = AccountAuthArgs & {
	readonly password: string
}

export const reAuth = /*@__PURE__*/ createAsyncThunk(
	'account/reAuth',
	async ({ decryptPemPair, generatePasswordHash, password }: ReAuthArg, { dispatch, getState }) => {
		try {
			await dispatch(
				login({
					decryptPemPair,
					email: accountEmail$(getState()),
					generatePasswordHash,
					password,
				}),
			).unwrap()
		} catch {
			// Re-authentication errors have a special code. Yay.
			throw createReqError(ReqErrorCode.REAUTH_ERROR)
		}
	},
)

// Checks password by attempting login, used for account-deletion when password
// is not present
export const confirmPasswordLogin = /*@__PURE__*/ createAsyncThunk(
	'account/confirmLogin',
	async ({ decryptPemPair, generatePasswordHash, password }: ReAuthArg, { dispatch, getState }) => {
		return await dispatch(
			login({
				decryptPemPair,
				email: accountEmail$(getState()),
				generatePasswordHash,
				password,
			}),
		).unwrap()
	},
)
// /** Attempts to register a key to the account */
export const registerKey = /*@__PURE__*/ createAsyncThunk(
	'key-exchange/registerKey',
	async (args: AccountGenArgs, { dispatch, getState, extra: { http } }) => {
		const state = getState()
		const accountId = accountId$(state)
		const accountUserId = accountUserId$(state)
		const password = accountPassword$(state)

		if (!(accountId && accountUserId && password)) return null

		const data: KeyExchangeBody = {
			request_args: await getRegisterAccountKeyArgs(accountId, password, args),
			request_type: 'acctkey.register',
			user_id: accountUserId,
		}

		const keyRes = await dispatch(http.post<Maybe<RegisterKeyRes>>('/key_exchange', { data }))

		return keyRes?.acctkey
	},
)

export const requestAccountDeletionStatus = /*@__PURE__*/ createAsyncThunk(
	'account-delete/status',
	async (_, { dispatch, extra: { http } }) => {
		try {
			const status = await dispatch(http.get<AccountDeletionStatusRes>('/account_deletion'))
			return status.deletion_requested
		} catch {
			return false
		}
	},
)

export const requestAccountDeletion = /*@__PURE__*/ createAsyncThunk(
	'account-delete/request',
	async (_, { dispatch, extra: { http } }) => {
		try {
			const reqDelete = await dispatch(
				http.post<AccountDeletionRes>('/account_deletion', {
					data: { action: 'request_deletion' },
				}),
			)

			// Return true if the account is set for deletion or if it's already
			// set to be deleted
			return (
				reqDelete.status === 'deletion_requested' ||
				reqDelete.status === 'duplicate_request_ignored'
			)
		} catch {
			return false
		}
	},
)

export const requestVerificationEmail = /*@__PURE__*/ createAsyncThunk(
	'email-verify/request',
	async (_, { dispatch, getState, extra: { http } }) => {
		const accountId = accountId$(getState())

		assertNotNullish(accountId, 'Account Id')

		await dispatch(
			http.put('/account_verification', {
				data: { action: 'resend_email', account_id: accountId },
			}),
		)
	},
)

type ResetPwArg = WithGenPwHash & {
	readonly email: string
	readonly newPw: string
	readonly token: string
}

/**
 * Tries to reset the password using the new password hash scheme. If this
 * fails with an `UNSUPPORTED_AUTH_SCHEME` code, we return `false`, otherwise,
 * we return the status.
 */
const resetPwNew = /*@__PURE__*/ createAsyncThunk(
	'account/resetPwNew',
	async (
		{ email, generatePasswordHash, newPw, token }: ResetPwArg,
		{ dispatch, extra: { http } },
	) => {
		const password = await generatePasswordHash(newPw, email)
		const res = await dispatch(
			http.post<ResetPwRes>('/resetpw', {
				data: { email, password, token },
				isUnauthenticated: true,
			}),
		)

		return res.status
	},
)

/** The fallback after `resetPwNewAction` fails with `UNSUPPORTED_AUTH_SCHEME` */
const resetPwLegacy = /*@__PURE__*/ createAsyncThunk(
	'account/resetPwLegacy',
	async (
		{ email, newPw, token }: MOmit<ResetPwArg, 'generatePasswordHash'>,
		{ dispatch, extra: { http } },
	) => {
		const res = await dispatch(
			http.post<ResetPwRes>('/resetpw', {
				data: { email, password: newPw, token },
				isUnauthenticated: true,
			}),
		)

		return res.status
	},
)

export const resetPw = /*@__PURE__*/ createAsyncThunk(
	'account/resetpw',
	async (args: ResetPwArg & AccountGenArgs, { dispatch }) => {
		let isLegacy = false
		let status: ResetPwStatus

		try {
			status = await dispatch(resetPwNew(args)).unwrap()
		} catch (e) {
			// If this is an `UNSUPPORTED_AUTH_SCHEME` code, return special `false`
			if (isAPIErrorType(e, APIErrorCode.UNSUPPORTED_AUTH_SCHEME)) {
				isLegacy = true
				status = await dispatch(resetPwLegacy(args)).unwrap()
			} else {
				// Otherwise, re-throw the error
				throw e
			}
		}

		if (status !== 'login_updated') {
			throw new Error(`Invalid resetpw status ${status}`)
		}

		dispatch(setLegacy(isLegacy))

		// Login to get the state we need to register a new encrypted key pair
		await dispatch(login({ ...args, password: args.newPw })).unwrap()

		// Register the new encrypted key pair if we're not a legacy account
		if (!isLegacy) await dispatch(registerKey(args)).unwrap()

		// Reset the auth state (clear all the stuff we just did), as we'll redirect
		// to login. This is more of a UX decision as the user can then confirm the
		// new password by logging in themselves.
		dispatch(resetAction('reset_pw'))
	},
)

/** Attempts to retrieve a key from the account */
export const retrieveKey = /*@__PURE__*/ createAsyncThunk(
	'key-exchange/retrieve-key',
	async ({ generatePasswordHash }: WithGenPwHash, { dispatch, getState, extra: { http } }) => {
		const state = getState()
		const accountId = accountId$(state)
		const accountUserId = accountUserId$(state)
		const password = accountPassword$(state)

		if (!(accountId && accountUserId && password)) return null

		const data: KeyExchangeBody = {
			request_args: { secret: await generatePasswordHash(password, accountId) },
			request_type: 'acctkey.retrieve',
			user_id: accountUserId,
		}

		const keyRes = await dispatch(http.post<Maybe<RetrieveKeyRes>>('/key_exchange', { data }))

		return keyRes?.acctkey
	},
)

type SetEncryptedPrivateArg = {
	readonly decrypt: DecryptPemPairFn
	readonly key: Maybe<string>
}

// NOTE: If the encrypted private key changes, we need to remove the PEM, so
//  everything can be recalculated. In practice, we should almost never change
//  encryptedPrivate keys, and in most cases, this will only go from having
//  no key to having one. But if we do end up changing it, it'll probably
//  happen due to a password change, and decrypting with the current password
//  will then correctly set `hasPasswordError: true`, prompting the user to
//  enter a new password which will decrypt the key.
export const setEncryptedPrivate = /*@__PURE__*/ createAsyncThunk(
	'account/setEncryptedPrivate',
	({ decrypt, key: encryptedPrivate }: SetEncryptedPrivateArg, { getState }) => {
		const currentCryptoState = cryptoState$(getState())
		const updatedCryptoState =
			currentCryptoState.encryptedPrivate === encryptedPrivate ?
				currentCryptoState
			:	{
					...currentCryptoState,
					encryptedPrivate,
					privateKeyPem: null,
					publicKeyPem: null,
				}

		return handleEncryptedState(decrypt, updatedCryptoState)
	},
)

type SetPasswordArg = {
	readonly decrypt: DecryptPemPairFn
	readonly password: Maybe<string>
}

export const setPassword = /*@__PURE__*/ createAsyncThunk(
	'account/setPassword',
	({ decrypt, password }: SetPasswordArg, { getState }) =>
		handleEncryptedState(decrypt, { ...cryptoState$(getState()), password }),
)

/**
 * This is defined as an action since the memoize selector won't update based
 * on time (unless we add a periodically incrementing timestamp to the reducer
 * as well as the selector).
 */
export const isAccountTokenValid =
	(expiryInMinutes = 20): SThunk<boolean> =>
	(_, getState) => {
		const state = getState()
		const hasAccountToken = !!accountToken$(state)
		const accountTokenTs = accountTokenTS$(state)

		return hasAccountToken && +add(accountTokenTs, expiryInMinutes, 'm') > Date.now()
	}

////////// Selectors ///////////////////////////////////////////////////////////

const state$ = <T extends WithAccountState>(s: T) => s.account
const cryptoState$ = /*@__PURE__*/ createSelector(
	state$,
	(s): AccountCryptoState =>
		pick(s, ['encryptedPrivate', 'password', 'privateKeyPem', 'publicKeyPem']),
)

export const account$ = /*@__PURE__*/ createSelector(state$, (s) => s.account)
export const accountEmail$ = /*@__PURE__*/ createSelector(state$, (s) => s.email)
// NOTE: We fall back on `s.account?.account_id` for limited clients that may
//  have had this model without `accountId`. This was the state briefly when
//  5.0.0 was release, and was updated a few days later in 5.0.2
export const accountId$ = /*@__PURE__*/ createSelector(
	state$,
	(s): Maybe<string> => s.accountId || s.account?.account_id,
)
export const accountPassword$ = /*@__PURE__*/ createSelector(state$, (s) => s.password)
export const accountToken$ = /*@__PURE__*/ createSelector(state$, (s) => s.token)
export const accountTokenTS$ = /*@__PURE__*/ createSelector(state$, (s) => s.tokenTS)
export const accountUserId$ = /*@__PURE__*/ createSelector(
	state$,
	(s): Maybe<string> => s.userId || s.account?.users[0],
)
export const encryptedPrivate$ = /*@__PURE__*/ createSelector(state$, (s) => s.encryptedPrivate)
export const hasPasswordError$ = /*@__PURE__*/ createSelector(state$, (s) => s.hasPasswordError)
export const isAccountInit$ = /*@__PURE__*/ createSelector(state$, (s) => s.isInit)
export const isLegacyAccount$ = /*@__PURE__*/ createSelector(state$, (s) => s.isLegacy)
export const loginDeviceId$ = /*@__PURE__*/ createSelector(state$, (s) => s.deviceId)
export const parentSecret$ = /*@__PURE__*/ createSelector(state$, (s) => s.parentSecret)
export const parentSecretId$ = /*@__PURE__*/ createSelector(state$, (s) => s.parentSecretId)
export const privateKeyPem$ = /*@__PURE__*/ createSelector(state$, (s) => s.privateKeyPem)
export const publicKeyPem$ = /*@__PURE__*/ createSelector(state$, (s) => s.publicKeyPem)
export const pubSubToken$ = /*@__PURE__*/ createSelector(state$, (s) => s.pubSubToken)
export const tempKeys$ = /*@__PURE__*/ createSelector(state$, (s) => s.keys)

export const accountCreation$ = /*@__PURE__*/ createSelector(
	account$,
	(a): Maybe<number> => a?.created,
)
export const hasAccountPassword$ = /*@__PURE__*/ createSelector(accountPassword$, Boolean)
export const hasEncryptedPrivate$ = /*@__PURE__*/ createSelector(encryptedPrivate$, Boolean)
export const hasPrivateKey$ = /*@__PURE__*/ createSelector(privateKeyPem$, Boolean)
export const isAuthenticated$ = /*@__PURE__*/ createSelector(
	parentSecret$,
	accountId$,
	selectUnaryEvery,
)
