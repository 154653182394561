import { utcDate } from '@eturi/date-util'
import type { HttpReqMethod } from '@op/services'
import { bToHex, utf8ToUint8Array } from '@op/util'

let secretKey = ''
let cryptoKeyPromise: Promise<CryptoKey> | null = null

export const signPayload = async (
	accountId: string,
	parentSecret: string,
	parentSecretId: Maybe<string>,
	url: string,
	method: HttpReqMethod = 'GET',
	body = '',
) => {
	const { pathname, searchParams } = new URL(url)
	searchParams.sort()
	const sortedParams = searchParams.toString()

	if (!cryptoKeyPromise || secretKey !== parentSecret) {
		secretKey = parentSecret
		cryptoKeyPromise = crypto.subtle
			.importKey('raw', utf8ToUint8Array(secretKey), { name: 'HMAC', hash: 'SHA-256' }, false, [
				'sign',
			])
			.catch((e) => {
				cryptoKeyPromise = null
				throw e
			})
	}

	const [bodyHash, cryptoKey] = await Promise.all([sha256HashHex(body), cryptoKeyPromise])

	const pkgData = [method, pathname, sortedParams, bodyHash].join('\n')
	const pkgDataHash = await sha256HashHex(pkgData)
	const canonicalPkg = utf8ToUint8Array(`PW1-HMAC-SHA256\n${utcDate()}\n${pkgDataHash}`)
	const signedPkgHex = bToHex(await crypto.subtle.sign('HMAC', cryptoKey, canonicalPkg))

	return parentSecretId ?
			`${accountId}:1.${signedPkgHex}.${parentSecretId}`
		:	`${accountId}:${signedPkgHex}`
}

const DEFAULT_HASH = 'e3b0c44298fc1c149afbf4c8996fb92427ae41e4649b934ca495991b7852b855'

const sha256HashHex = async (data = '') => {
	// There is no data, so skip calculating the hash. SHA256 will always
	// calculate this value for an empty string.
	if (data === '') return DEFAULT_HASH

	const digest = await crypto.subtle.digest('SHA-256', utf8ToUint8Array(data))

	return bToHex(digest)
}
