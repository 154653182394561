/**
 * @fileOverview A collection of utilities for converting different binary formats
 */

/**
 * A binary source. `string` is treated as a raw byte string. For base64, use `b64ToUint8Array`
 */
export type BinarySource = ArrayBufferLike | Uint8Array | string

/**
 * Normalizes any `BinarySource` to `Uint8Array`
 * @param src
 */
export const bToUint8Array = (src: BinarySource) => {
	if (src instanceof Uint8Array) return src

	// byte string
	if (typeof src == 'string') return strToUint8Array(src)

	return new Uint8Array(src)
}

/**
 * Takes a binary source and returns the base64 encoded string
 * @param src
 * @returns base64 encoded string
 */
export const bToB64 = (src: BinarySource) => btoa(bToStr(src))

/**
 * Converts a base64-encoded binary string to Uint8Array
 * @param base64
 */
export const b64ToUint8Array = (base64: string) => strToUint8Array(atob(base64))

/**
 * Converts a `BinarySource` to hexadecimal string
 * @param src
 */
export const bToHex = (src: BinarySource) =>
	Array.from(bToUint8Array(src), (b) => b.toString(16).padStart(2, '0')).join('')

/**
 * Converts a `BinarySource` into the integer it represents. Note this uses an indexed for-loop
 * because `Uint8Array` has direct memory access, and is hugely faster (5x) using direct index
 * lookups compared with using Iterator or reduce.
 * @param src
 */
export const bToNumber = (src: BinarySource) => {
	const bytes = bToUint8Array(src)
	let num = 0

	for (let i = 0; i < bytes.length; i++) {
		num = (num << 8) | bytes[i] // Shift left and add new byte
	}

	return num
}

/**
 * Converts a `BinarySource` to a BigInt. Same implementation as `bToNumber`, and optimized
 * in the same way.
 * @param src
 */
export const bToBigInt = (src: BinarySource) => {
	const bytes = bToUint8Array(src)
	const bigInt8 = BigInt(8)
	let num = BigInt(0)

	for (let i = 0; i < bytes.length; i++) {
		num = (num << bigInt8) | BigInt(bytes[i])
	}

	return num
}

/**
 * Takes a binary source and returns a raw byte string. Note that if a string is passed, it is
 * simply returned unchanged, since the `BinarySource` of type `string` is considered a raw byte
 * string already.
 *
 * @param src A binary source
 * @returns A raw byte string
 */
export const bToStr = (src: BinarySource) => {
	if (typeof src == 'string') return src

	const bytes = bToUint8Array(src)
	// This is roughly half the max arguments allowed by JS VMs.
	const CHUNK_SZ = 0x8000

	const c: string[] = []

	for (let i = 0; i < bytes.length; i += CHUNK_SZ) {
		c.push(String.fromCharCode(...bytes.subarray(i, i + CHUNK_SZ)))
	}

	return c.join('')
}

/**
 * Converts a raw byte string to a Uint8Array.
 * NOTE: This must only be used to raw byte strings, NOT normal UTF-8 strings. For example,
 *  when you base64-decode a PEM, you have a raw byte string. When you get a user password,
 *  on the other hand, you have a normal UTF-8 string, and should use `utf8ToUint8Array`.
 * @param str
 */
export const strToUint8Array = (str: string) => {
	// We preallocate and use a loop because it's about 20x faster than `Uint8Array.from`
	const bytes = new Uint8Array(str.length)

	for (let i = 0; i < str.length; i++) {
		bytes[i] = str.charCodeAt(i)
	}

	return bytes
}

/**
 * Takes in a normal UTF-8 string and returns the `Uint8Array`.
 * NOTE: The reason this is here is to differentiate it from `strToUint8Array`, which works
 *  specifically on raw byte strings and should NOT be used for converting normal strings.
 * @param str
 */
export const utf8ToUint8Array =
	typeof TextEncoder == 'function' ?
		(str: string) => new TextEncoder().encode(str)
	:	(str: string) => strToUint8Array(unescape(encodeURIComponent(str)))
