import { pick } from '@eturi/util'
import type { Dispatch, Slice, StoreEnhancer } from '@reduxjs/toolkit'
import every from 'lodash/every.js'
import identity from 'lodash/identity.js'
import noop from 'lodash/noop.js'
import type { AsyncStorage } from './types/index.js'

export type PersistenceOnReady = () => void

export type PersistenceExtra = {
	readonly onReady: (cb: PersistenceOnReady) => void
}

export type PersistenceRehydrate<T = any> = (partialState: Partial<T>) => T | Promise<T>

type MaybeReadonlyArr<T> = T[] | readonly T[]

// FIXME: Individual state kv storage
//type StateKeyConf<State> =
//	| keyof State
//	| {
//			readonly key: keyof State
//			readonly storage?: AsyncStorage
//			readonly storeKey?: string
//	  }

export type PersistenceConfig<State = any> = {
	readonly allowList?: MaybeReadonlyArr<keyof State>
	readonly blockList?: MaybeReadonlyArr<keyof State>
	readonly rehydrate?: PersistenceRehydrate<State>
	readonly storage?: AsyncStorage
	readonly storeKey?: string
	readonly storePrefix?: string
}

/**
 * Helper to infer State from slice to get a `PersistConfig<State>`
 */
type GetPersistConfigFromSlice<S extends Slice> =
	S extends Slice<infer State, any, any> ? PersistenceConfig<State> : never

/**
 * Helper to get a typed NormalizedPersistenceConfig from a slice
 */
type NormalizedConfigWithSlice<S extends Slice> =
	S extends Slice<infer State, any, infer Name> ? NormalizedPersistenceConfig<State, Name>
	:	NormalizedPersistenceConfig

/**
 * Defines a function that takes in default storage and returns a normalized
 * persistence config.
 */
export type GetPersistConf<State = any, Name extends string = string> = (
	defaultStorage: AsyncStorage,
) => NormalizedPersistenceConfig<State, Name>

/**
 * Helper function that takes in a slice name and an optional config and
 * returns a function that takes in the default storage and returns the
 * normalized config object. This function as well as `persistState` are mainly
 * used to provide good types, since getting types from a list of configs was
 * something I found impossible.
 * @param slice
 * @param conf
 */
export const persistSlice =
	<S extends Slice>(slice: S, conf?: GetPersistConfigFromSlice<S>) =>
	(defaultStorage: AsyncStorage): NormalizedConfigWithSlice<S> =>
		normalizeConf(slice.name, defaultStorage, conf) as NormalizedConfigWithSlice<S>

/**
 * Serves the same function as `persistSlice`, as a TypeScript helper.
 * @see persistSlice
 * @param stateKey
 * @param conf
 */
export const persistState =
	<State = any, Name extends string = string>(stateKey: Name, conf?: PersistenceConfig<State>) =>
	(defaultStorage: AsyncStorage): NormalizedPersistenceConfig<State, Name> =>
		normalizeConf(stateKey, defaultStorage, conf)

const normalizeConf = <State = any, Name extends string = string>(
	stateKey: Name,
	storage: AsyncStorage,
	conf: Partial<PersistenceConfig<State>> = {},
): NormalizedPersistenceConfig<State, Name> => ({
	rehydrate: identity,
	storage: conf.storage || storage,
	storeKey: (conf.storePrefix || DEFAULT_STORE_PREFIX) + stateKey,
	stateKey,
	...conf,
})

const DEFAULT_STORE_PREFIX = '__PERSIST__'

type NormalizedPersistenceConfig<State = any, Name extends string = string> = {
	readonly stateKey: Name
} & WithRequired<PersistenceConfig<State>, 'rehydrate' | 'storage' | 'storeKey'>

export type PersistenceEnhancer = StoreEnhancer<PersistenceExtra>

/**
 * Takes in the state and a config and returns the slice after applying any
 * allowList / blockList filters.
 */
const getFilteredSlice = <S extends Record<string, unknown>, K extends keyof S>(
	state: S,
	{ blockList, allowList }: NormalizedPersistenceConfig<S>,
) => {
	if (!(blockList || allowList)) return state

	let pickKeys = allowList || (Object.keys(state) as K[])

	if (blockList) pickKeys = pickKeys.filter((k) => !blockList.includes(k))

	return pick(state, pickKeys)
}

const serialize = <T>(value: T) => JSON.stringify(value)
const deserialize = <T = any>(value: string): T => JSON.parse(value)

const REHYDRATE_COMPLETE = '__OP_PERSISTENCE_REHYDRATE_COMPLETE__'
const PERSISTENCE_INIT_ACTION = { type: '__OP_PERSISTENCE_INIT__' } as const

/**
 * Main enhancer creator takes in a list of `getConfig` functions. These are
 * the return value of `persistSlice` or `persistState`. Also takes in the
 * default storage instance.
 */
export const createPersistenceEnhancer = <T extends GetPersistConf>(
	opts: T[],
	storage: AsyncStorage,
	notifier: (key: string, value: string) => void = noop,
): PersistenceEnhancer => {
	return (createStore) => {
		// Maps all the `getConf` options to actual config objects
		const configs = opts.map((getConf) => getConf(storage))

		// Set of `onReady` listeners
		const listeners = new Set<PersistenceOnReady>()

		// Locally track whether we've done a rehydrate, so we can call `onReady`
		// listeners when ready.
		let isReady = false

		/**
		 * The main persistence handler. This takes in the old state and a
		 * `getState` function, and asynchronously gets the new state and compares
		 * it to the old state. If there are changes for any of the slices that
		 * have been configured, those slices are persisted. This is currently on a
		 * 200ms throttle to keep persistence thrashing lower.
		 *
		 * NOTE: Consider testing / tuning the throttle duration.
		 */
		const doPersist = (currentState: any, newState: any) => {
			// If the whole state is unchanged, do nothing
			if (currentState === newState) return

			// Iterate all the configs to compare slices
			configs.forEach(async (config) => {
				const stateKey = config.stateKey
				const s1Slice = currentState[stateKey]
				const s2Slice = newState[stateKey]

				// If slices are unchanged, do nothing
				if (s1Slice === s2Slice) {
					return
				}

				const s2FilteredSlice = getFilteredSlice(s2Slice, config)

				// If filtered slice isn't the same object as original slice, it means
				// we're using allowList / blockList to create a partial slice. If so,
				// we don't save if the picked state is the same as s1Slice values.
				// Because the redux store is immutable, all checks are strict equality.
				if (s2FilteredSlice !== s2Slice && every(s2FilteredSlice, (v, k) => v === s1Slice[k])) {
					return
				}

				try {
					const value = serialize(s2FilteredSlice)
					await config.storage.setItem(config.storeKey, value)
					notifier(stateKey, value)
				} catch (e) {
					console.error('Error persisting state', e)
				}
			})
		}

		/**
		 * This is the main init function for resolving and rehydrating state. It takes in the initial
		 * state and preloaded state (if any), and merges into a single rehydrated initial state. The
		 * merging of slices is done in order. The pristine initial state slice is used as default. If
		 * there's a stored slice, this is merged into pristine slice. And if there's a partial
		 * preloaded slice, this is merged into the combination. Thus, preloaded state has the highest
		 * merge priority.
		 */
		const initReadAndRehydrate = async <S>(
			dispatch: Dispatch<any>,
			pristineInitialState: S,
			preloadedState: Partial<S> = {},
		) => {
			// Note that in practice, using the configurations in our apps, this function never gets
			// called more than once, so this variable is superfluous. But if it ever were called more
			// than once, it would return immediately, as long as the initial call had finished. However,
			// if it were called a second time while the original promise was still resolving, either one
			// could resolve first, which would lead to non-deterministic behavior. If this occurs, we
			// want to know about it, as we'll have to solve for the desired behavior. So we'll log it
			// as an error.
			if (isReady) return console.error(new Error('initReadAndRehydrate called too many times!'))

			// Make a copy of
			const rehydratedState = { ...pristineInitialState }

			/**
			 * Helper that merges states in order of precedence. If there's neither a preloaded slice nor
			 * a persisted slice for this config, we just return. Otherwise, we merge in order and try
			 * to rehydrate.
			 */
			const mergeStateSlice = async (conf: NormalizedPersistenceConfig, persistedSlice?: any) => {
				const stateKey = conf.stateKey as keyof S & string
				const preloadedSlice = preloadedState?.[stateKey]

				if (!(persistedSlice || preloadedSlice)) return

				rehydratedState[stateKey] = await Promise.resolve(
					conf.rehydrate({
						...rehydratedState[stateKey],
						...persistedSlice,
						...preloadedSlice,
					}),
				)
			}

			await Promise.all(
				configs.map(async (conf) => {
					const { storage, storeKey } = conf

					try {
						const persistedSliceStr = await storage.getItem(storeKey)

						// This is obtuse to minimize footprint. Basically, always try to merge state slice.
						// If there's a persisted slice string, try passing the deserialized result, or if
						// falsey, pass undefined.
						return mergeStateSlice(
							conf,
							(persistedSliceStr && deserialize(persistedSliceStr)) || undefined,
						)
					} catch (e) {
						console.error(e, 'Error rehydrating state')
					}

					try {
						// If we get here, we failed to merge, but we want to at least try to merge the
						// preloaded state into the initial state.
						return mergeStateSlice(conf)
					} catch (e) {
						console.error(e, 'Error merging preloaded state into initial state')
					}
				}),
			)

			// Set isReady as soon as this is done. See above for info on this.
			isReady = true

			// Dispatch REHYDRATE_COMPLETE, passing the rehydrated state
			dispatch({ type: REHYDRATE_COMPLETE, payload: rehydratedState })

			// Call all the listeners, so PersistGate or any other listeners know we're initialized.
			listeners.forEach((cb) => cb())

			// Since this is done once clear the set
			listeners.clear()
		}

		return (reducer, preloadedState) => {
			// Our new reducer is a simple guard that catches a `REHYDRATE` action
			// and calls `rehydrate`, otherwise it just calls the original reducer
			const persistReducer = (state: any, action: any) =>
				action.type === REHYDRATE_COMPLETE ? action.payload : reducer(state, action)

			const newStore = createStore(persistReducer, preloadedState as any)
			const origDispatch = newStore.dispatch

			// Our new dispatch intercepts actions to call `doPersist` w/ `oldState`
			// and the `getState` for getting new state.
			const dispatch: typeof origDispatch = (action) => {
				const currentState = newStore.getState()

				const ret = origDispatch(action)

				doPersist(currentState, newStore.getState())

				return ret
			}

			// One-time slice init w/ persistence read and rehydrate
			initReadAndRehydrate(
				dispatch,
				persistReducer(undefined, PERSISTENCE_INIT_ACTION),
				preloadedState as any,
			)

			// New store has our new dispatch as well as an `onReady` function to
			// allow listeners to be notified when our state has been rehydrated.
			// This allows us to show a loading spinner / delay app loading until
			// the rehydrated state is ready.
			return {
				...newStore,
				dispatch,
				onReady: (cb) => {
					// Immediately call the callback if onReady handler is added after
					// we're already rehydrated.
					if (isReady) return cb()

					listeners.add(cb)
				},
			}
		}
	}
}
