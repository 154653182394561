import { arrayAddOrUpdate, assertNotNullish, omit, setIfNotEqual } from '@eturi/util'
import { toParamsURL } from '@op/util'
import type { Draft, PayloadAction } from '@reduxjs/toolkit'
import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { castDraft } from 'immer'
import find from 'lodash/find.js'
import mapValues from 'lodash/mapValues.js'
import reject from 'lodash/reject.js'
import { createSliceTransformer } from 'rtk-slice-transformer'
import { resetAction } from '../actions/index.js'
import { bindCreateAsyncThunkToState } from '../bindCreateAsyncThunkToState.js'
import type { HttpExtra } from '../http.js'
import type {
	CommandReqBody,
	IdPayloadAction,
	InitState,
	OverrideActionType,
	OverrideType,
	RawRule,
	Schedule,
	SThunkState,
} from '../types/index.js'
import {
	createIdPayloadPrepare,
	isOverrideRule,
	OverrideDuration,
	pickIdPayload,
	scheduleToRawRule,
} from '../types/index.js'

export type UserRuleState = InitState & {
	readonly rules: readonly RawRule[]
}

export const createUserRuleState = (): UserRuleState => ({
	isInit: false,
	rules: [],
})

export type RuleState = {
	readonly [userId: string]: UserRuleState
}

export type WithRuleState = {
	readonly rule: RuleState
}

const initialState: RuleState = {}

const ensureUserState = (s: Draft<RuleState>, userId: string) =>
	(s[userId] ||= castDraft(createUserRuleState()))

export const ruleSlice = /*@__PURE__*/ createSlice({
	name: 'rule',
	initialState,
	reducers: {
		addOrUpdateRule: {
			prepare: createIdPayloadPrepare<RawRule>(),
			reducer(s, a: IdPayloadAction<RawRule>) {
				const [userId, rule] = pickIdPayload(a)
				const userState = ensureUserState(s, userId)

				if (isOverrideRule(rule)) {
					_removeOrReplaceOverride(userState, rule)
				} else {
					userState.rules = arrayAddOrUpdate(userState.rules, rule, 'rule_id')
				}
			},
		},

		removeRule: {
			prepare: createIdPayloadPrepare<string>(),
			reducer(s, a: IdPayloadAction<string>) {
				const [userId, ruleId] = pickIdPayload(a)
				const userState = ensureUserState(s, userId)
				const updatedRules = reject(userState.rules, { rule_id: ruleId })

				if (userState.rules.length !== updatedRules.length) {
					userState.rules = updatedRules
				}
			},
		},

		setRules: {
			prepare: createIdPayloadPrepare<RawRule[]>(),
			reducer(s, a: IdPayloadAction<RawRule[]>) {
				const [userId, rules] = pickIdPayload(a)

				setIfNotEqual(ensureUserState(s, userId), 'rules', rules)
			},
		},

		// FIXME: Currently only for tests
		removeUserOverride(s, a: PayloadAction<string>) {
			_removeOrReplaceOverride(ensureUserState(s, a.payload))
		},
	},
	extraReducers: (builder) =>
		builder
			.addCase(resetAction, () => initialState)
			.addCase(deleteUserCmd.fulfilled, (s, { meta: { arg: userId } }) => {
				_removeOrReplaceOverride(ensureUserState(s, userId))
			})
			.addCase(fetchUserRules.fulfilled, (s, a) => {
				ensureUserState(s, a.meta.arg.userId).isInit = true
			}),
})

export const { addOrUpdateRule, removeRule, removeUserOverride, setRules } = ruleSlice.actions

const isRuleAction = /*@__PURE__*/ isAnyOf(
	addOrUpdateRule,
	removeRule,
	setRules,
	removeUserOverride,
)

export const ruleSliceTransformer = /*@__PURE__*/ createSliceTransformer(
	ruleSlice,
	(s) =>
		mapValues(s, (u) => ({
			isInit: u.isInit,
			rules: u.rules.map((r) => omit(r, ['account_id', 'user_id', 'rule_id', 'ruledef_id'])),
		})),
	(a) => (isRuleAction(a) ? null : a),
)

const _removeOrReplaceOverride = (s: Draft<UserRuleState>, rule?: RawRule) => {
	// FIXME: TS 4.7 cast isOverrideRule<RawRule>
	const updatedRules = reject(s.rules, isOverrideRule) as RawRule[]

	if (rule) updatedRules.push(rule)

	setIfNotEqual(s, 'rules', updatedRules)
}

////////// Thunks //////////////////////////////////////////////////////////////

export type RuleThunkState = SThunkState & WithRuleState

const createAsyncThunk = /*@__PURE__*/ bindCreateAsyncThunkToState<RuleThunkState>()

// NOTE: We can't easily optimistically add the schedule here and use rollback
//  in this case, b/c we don't have a `rule_id` yet. Thus, if we add this
//  immediately, we'll end up with duplicate rules when `addOrUpdateRules`
//  gets called.
export const addUserSchedule = /*@__PURE__*/ createAsyncThunk(
	'rule/schedule/add',
	async (schedule: Schedule, { dispatch, extra: { http } }) => {
		const rawRule = scheduleToRawRule(schedule)
		const userId = rawRule.user_id

		// NOTE: These mutations aren't problematic b/c they aren't propagated to
		//  anything downstream. The rule will be overridden if POST is successful.
		//  and we don't optimistically add the rule so nothing needs to be rolled
		//  back (as noted above).
		;(rawRule as any).ruledef_name = 'schedule'
		delete (rawRule as any).ruledef_id

		// POST returns a list with only the added rule
		const result = await dispatch(
			http.post<Maybe<[RawRule]>>(`/rule?user_id=${userId}`, { data: [[rawRule]] }),
		)

		const rawSchedule = result?.[0]

		assertNotNullish(rawSchedule, 'RawRule')

		dispatch(addOrUpdateRule(userId, rawSchedule))

		return rawSchedule.rule_id
	},
)

export const deleteUserCmd = /*@__PURE__*/ createAsyncThunk(
	'rule/cmd/delete',
	async (user_id: string, { dispatch, extra: { http } }) => {
		await dispatch(http.delete('/command', { data: { user_id } }))
	},
)

export const deleteUserSchedule = /*@__PURE__*/ createAsyncThunk(
	'rule/user/schedule/delete',
	async (schedule: Schedule, { dispatch, getState, extra: { http } }) => {
		const { rule_id, user_id } = schedule
		const rollbackValue = _getRollbackSchedule(getState(), schedule)

		assertNotNullish(rollbackValue, `Missing raw schedule to remove ${rule_id}`)

		// Optimistically remove the associated schedule rule
		dispatch(removeRule(user_id, rule_id))

		try {
			await dispatch(http.delete(toParamsURL('/rule', { rule_ids: rule_id, user_id })))
		} catch (e) {
			dispatch(addOrUpdateRule(user_id, rollbackValue))
			throw e
		}
	},
)

type FetchUserRulesArg = HttpExtra & {
	readonly userId: string
}

export const fetchUserRules = /*@__PURE__*/ createAsyncThunk(
	'rules/user/fetch',
	async ({ userId, ...extra }: FetchUserRulesArg, { dispatch, extra: { http } }) => {
		const rules = await dispatch(http.get<Maybe<RawRule[]>>(`/rule?user_id=${userId}`, extra))

		assertNotNullish(rules, 'RawRule[]')

		dispatch(setRules(userId, rules))
	},
	{
		condition: (arg, api) => {
			if (!arg.force && ruleState$(api.getState())[arg.userId]?.isInit) return false
		},
	},
)

type InstallChildAppArg = {
	readonly deviceId: string
	readonly userId: string
}

/** A special command that will trigger a child app installation on iOS */
export const installChildApp = /*@__PURE__*/ createAsyncThunk(
	'rule/installChildApp',
	async ({ deviceId, userId }: InstallChildAppArg, { dispatch, extra: { http } }) => {
		await dispatch(
			http.post('/command', {
				data: {
					command: 'install_childapp',
					device_id: deviceId,
					rule_type: 'command',
					user_id: userId,
				} satisfies CommandReqBody,
			}),
		)
	},
)

type SendUserOverrideArg = {
	readonly action?: OverrideActionType
	readonly duration: number
	readonly type: OverrideType
	readonly userId: string
}

/** Sends user command. As w/ schedule, we can't easily be optimistic */
export const sendUserOverride = /*@__PURE__*/ createAsyncThunk(
	'rule/cmd/send',
	async (arg: SendUserOverrideArg, { dispatch, extra: { http } }) => {
		// Duration of 0 and -1 are special cases for UNTIL_I_SAY_SO and MIDNIGHT
		// If we get one of those values we set the duration as null and set the
		// action property. If the server receives no value for both duration
		// and action then it knows to set the override to UNTIL_I_SAY_SO
		let action = arg.action
		if (arg.duration === OverrideDuration.MIDNIGHT) action = 'until_midnight'
		if (arg.duration === OverrideDuration.UNTIL_I_SAY_SO) action = undefined

		const rawRule = await dispatch(
			http.post<Maybe<RawRule>>('/command', {
				data: {
					action,
					command: arg.type,
					duration: arg.duration > 0 ? `PT${arg.duration}M` : null,
					user_id: arg.userId,
				} satisfies CommandReqBody,
			}),
		)

		assertNotNullish(rawRule, 'RawRule')

		dispatch(addOrUpdateRule(arg.userId, rawRule))
	},
)

export const sendUserLocationBurst = /*@__PURE__*/ createAsyncThunk(
	'rule/locationBurst/send',
	async (userId: string, { dispatch, extra: { http } }) => {
		await dispatch(
			http.post('/command', {
				data: {
					command: 'location_burst',
					user_id: userId,
				} satisfies CommandReqBody,
			}),
		)
	},
)

export const updateUserSchedule = /*@__PURE__*/ createAsyncThunk(
	'rule/schedule/update',
	async (schedule: Schedule, { dispatch, getState, extra: { http } }) => {
		const { rule_id, user_id } = schedule
		const rollbackValue = _getRollbackSchedule(getState(), schedule)

		assertNotNullish(rollbackValue, `Missing raw schedule to update ${rule_id}`)

		const rawRule = scheduleToRawRule(schedule)

		// Optimistically update schedule rule
		dispatch(addOrUpdateRule(user_id, rawRule))

		try {
			// PUT returns all rules
			const rawRules = await dispatch(
				http.put<Maybe<RawRule[]>>(`/rule?user_id=${user_id}`, { data: [[rawRule]] }),
			)

			assertNotNullish(rawRules, 'RawRule[]')

			dispatch(setRules(user_id, rawRules))
		} catch (e) {
			dispatch(addOrUpdateRule(user_id, rollbackValue))
			throw e
		}
	},
)

const _getRollbackSchedule = (s: RuleThunkState, { rule_id, user_id }: Schedule): Maybe<RawRule> =>
	find(ruleState$(s)[user_id]?.rules || [], { rule_id })

////////// Selectors ///////////////////////////////////////////////////////////

export const ruleState$ = <T extends WithRuleState>(s: T) => s.rule
