import { size } from './size.js'

export type KeysToInt<T, K extends keyof T> = { [P in K]: number }

export const keysToInt = <T, K extends keyof T>(
	obj: Maybe<T>,
	keys: readonly K[] | K[],
): KeysToInt<T, K> => {
	const newObj: any = {}

	for (const key of keys) {
		const v = obj?.[key]
		newObj[key] = Number.isFinite(v) ? v : size(v as any)
	}

	return newObj
}
