import type {
	AccountAuthArgs,
	AccountGenArgs,
	DecryptPemPairFn,
	DecryptSampleImgFn,
	DecryptTempKeysFn,
	EncryptPrivateKeyFn,
	GeneratePasswordHashFn,
	GeneratePemPairFn,
	GetKeyIdFn,
} from '@op/services'
import { generateKeyId } from '@op/web-crypto'
import { cryptoWorkerPool } from '../cryptoWorkerPool'

export const decryptSampleImg: DecryptSampleImgFn = async (
	encryptedBuffer,
	decryptedKey,
	b64IV,
) => {
	const decryptedBuffer = await cryptoWorkerPool.exec('decrypt_buf', {
		b64IV,
		decryptedKey,
		encryptedBuffer,
	})

	return URL.createObjectURL(new Blob([decryptedBuffer], { type: 'image/jpeg' }))
}

export const decryptTempKeys: DecryptTempKeysFn = (encryptedKeys, privateKeyPem) =>
	cryptoWorkerPool.exec('decrypt_tmp', {
		encryptedKeys,
		privateKeyPem,
	})

export const decryptPemPair: DecryptPemPairFn = (encryptedPrivatePem, password) =>
	cryptoWorkerPool.exec('decrypt_pem_pair', { encryptedPrivatePem, password })

export const encryptPrivateKey: EncryptPrivateKeyFn = (privateKeyPem, password) =>
	cryptoWorkerPool.exec('enc_pk', {
		privateKeyPem,
		password,
	})

export const getKeyId: GetKeyIdFn = (() => {
	const publicKeyCacheMap = new Map<string, Promise<string>>()
	const nullPromise = Promise.resolve(null)

	return (publicKeyPem: Maybe<string>) => {
		if (!publicKeyPem) return nullPromise

		let keyIdPromise = publicKeyCacheMap.get(publicKeyPem)

		publicKeyCacheMap.set(publicKeyPem, (keyIdPromise ||= generateKeyId(publicKeyPem)))

		return keyIdPromise
	}
})()

export const generatePemPair = (async () => {
	return await cryptoWorkerPool.exec('gen_pem_pair')
}) satisfies GeneratePemPairFn

export const generatePasswordHash: GeneratePasswordHashFn = (password, salt) =>
	cryptoWorkerPool.exec('gen_pw_hash', { password, salt })

export const accountGenArgs = (): AccountGenArgs => ({
	decryptPemPair,
	encryptPrivateKey,
	generateKeyId,
	generatePemPair,
	generatePasswordHash,
})

export const accountAuthArgs = (): AccountAuthArgs => ({
	decryptPemPair,
	generatePasswordHash,
})
