import has from 'lodash/has.js'

export type APIErrorCode = ValueUnion<typeof APIErrorCode>
export const APIErrorCode = {
	/* Errors based on HTTP Status code */
	//NOT_AUTHORIZED: 1,

	// Duplicates
	DUPLICATE_EMAIL: 100,
	//DUPLICATE_NAME: 101,

	// Malformed
	//MALFORMED_PASSWORD: 200,
	//MALFORMED_EMAIL: 201,
	//MALFORMED_ID: 202,
	UNSUPPORTED_AUTH_SCHEME: 210,

	// Invalid (validation errors)
	INVALID_PASSWORD: 300,
	INVALID_EMAIL: 301,
	//INVALID_TIMEZONE: 302,
	//INVALID_GENDER: 303,
	//INVALID_BIRTHDAY: 304,
	//INVALID_DEVICE_TYPE: 305,
	//INVALID_OBJECT_TYPE: 306,
	//INVALID_PARAMETERS: 307,
	//INVALID_USER_TYPE: 308,
	//INVALID_RULE_NAME: 309,
	//INVALID_DAYS: 310,
	//INVALID_DURATION: 311,
	//INVALID_TOKEN: 312,
	//INVALID_NAME: 313,
	//INVALID_ALLOWANCE: 314,
	//INVALID_COMMAND: 315,
	//INVALID_DATA: 316,
	//INVALID_MESSAGE_BODY: 317,
	//INVALID_MESSAGE_TYPE: 318,
	//INVALID_LOCATION_DATA: 319,
	//INVALID_RULE_DEFINITION: 320,
	//INVALID_RULE_ARGS: 321,
	//INVALID_LOCALE: 322,
	//INVALID_GEOFENCE_DATA: 323,
	//INVALID_NOTIFICATION_ACTION: 324,

	// Doesn't exist, inactive, or retired.
	//NOT_ACTIVE: 400,
	//DEVICE_NOT_ACTIVE: 401,
	//USER_NOT_ACTIVE: 402,
	//ACCOUNT_NOT_ACTIVE: 403,
	//NOT_FOUND: 404,
	//NOT_ALLOWED: 405,
	//DEVICE_NOT_FOUND: 406,
	//USER_NOT_FOUND: 407,
	//ACCOUNT_NOT_FOUND: 408,
	//RULE_NOT_FOUND: 409,
	INSUFFICIENT_PRIVILEGES: 410,
	//PLAY_PAUSE_NOT_ALLOWED: 411,
	//OBJECT_IN_USE: 412,
	RATE_LIMIT_EXCEEDED: 413,
	//DUPLICATE_PURCHASE_DISALLOWED: 420,
	//MULTI_PLATFORM_PURCHASE_DISALLOWED: 421,
	//UNSUPPORTED_WEBHOOK: 430,
	//STRIPE_CARD_ERROR: 431,
	//GEOFENCE_NOT_FOUND: 432,
	COUPON_NOT_VALID: 433,
	//NOTIFICATION_ACTION_NOT_ALLOWED: 434,
	//COUPON_EXISTS: 435,
	//AUTHENTICATION_REVOKED: 440,
	//FEATURE_NOT_ACTIVE: 441,

	// Server problem
	UNKNOWN_ERROR: 500,
	//SERVER_DATABASE_ERROR: 505,
	//S3_UPLOAD_ERROR: 504,
	//COUPON_DEFINITION_INVALID: 510,

	// Permission denied
	//PERMISSION_DENIED_VEW: 600,
	//PERMISSION_DENIED_ADD_DEVICE: 601,
	//PERMISSION_DENIED_ADD_USER: 602,
	//PERMISSION_DENIED_VEW_NOW: 603,
	//PERMISSION_DENIED_AUTOMATED: 604,
	//PERMISSION_DENIED_DEVICE_SUSPENDED: 605,
} as const

export type ReqErrorCode = ValueUnion<typeof ReqErrorCode>
export const ReqErrorCode = {
	/* Synthetic codes */
	REAUTH_ERROR: -2,
	//AUTH_SIGNING_ERROR: -1,

	/* Network error or opaque response */
	NETWORK_ERROR: 0,

	/* Request errors */
	BAD_REQUEST: 400,
	UNAUTHORIZED: 401,
	FORBIDDEN: 403,
	//NOT_FOUND: 404,
	//SERVER_ERROR: 500,
} as const

export type ErrorType = 'api' | 'req'
export type ErrorCode = APIErrorCode | ReqErrorCode

export type OPError = {
	readonly code: ErrorCode
	readonly type: ErrorType
}

export type APIErrorType<T extends OPError = OPError, C extends APIErrorCode = APIErrorCode> = T & {
	readonly code: C
	readonly type: 'api'
}

export type ReqErrorType<T extends OPError = OPError, C extends ReqErrorCode = ReqErrorCode> = T & {
	readonly code: C
	readonly type: 'req'
}

// We use 'has' because 'type' can be '0'. When checking that property directly
// it will return a falsy response
export const isOPError = (err: any): err is OPError => has(err, 'type') && has(err, 'code')
export const createAPIError = (code: APIErrorCode): APIErrorType => ({ type: 'api', code })
export const createReqError = (code: ReqErrorCode): ReqErrorType => ({ type: 'req', code })
export const isAPIError = <T extends OPError>(err: T): err is APIErrorType<T> => err.type === 'api'
export const isReqError = <T extends OPError>(err: T): err is ReqErrorType<T> => err.type === 'req'

export const isAPIErrorType = (err: any, code: APIErrorCode) =>
	isOPError(err) && isAPIError(err) && err.code === code

export const isReqErrorType = (err: any, code: ReqErrorCode) =>
	isOPError(err) && isReqError(err) && err.code === code
