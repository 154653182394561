import { createSelector } from '@reduxjs/toolkit'
import every from 'lodash/every.js'
import { activeChild$, rawSortedFilteredChildren$ } from '../reducers/index.js'
import { isMinPremAccess$ } from './access.js'

export const isGeoEnabledForAllUsers$ = /*@__PURE__*/ createSelector(
	rawSortedFilteredChildren$,
	(c) => every(c, 'user_location_active'),
)

export const isGeoEnabledForActiveChild$ = /*@__PURE__*/ createSelector(activeChild$, (c) =>
	Boolean(c?.user_location_active),
)

export const canAccessLocator$ = /*@__PURE__*/ createSelector(
	isGeoEnabledForAllUsers$,
	isMinPremAccess$,
	(isGeoEnabled, isMinPrem) => Boolean(isGeoEnabled && isMinPrem),
)
