import { getLocalStorage } from '@op/react-web'
import type { Lang } from '@op/services'
import type {
	BackendModule,
	InitOptions,
	LoggerModule,
	ReadCallback,
	Resource,
	ResourceLanguage,
	Services,
} from 'i18next'

const STORAGE_KEY = '__OP_i18n__'
const LOG_TAG = '[LangBackend]'

type I18nStore = {
	t: Resource
	version: string
}

export class LangBackend implements BackendModule {
	static readonly type = 'backend'
	i18nOpts: InitOptions
	services: Services
	readonly type = 'backend'

	private _requiresUpgrade = false

	init(s: Services, _: object, i18nOpts: InitOptions) {
		this.services = s
		this.i18nOpts = i18nOpts
	}

	async read(lng: string, _ns: string, cb: ReadCallback) {
		const fromStorage = this._readStorage(lng)

		if (fromStorage) {
			this._l.log(LOG_TAG, `Found translation cache for '${lng}' from storage`)

			return cb(null, fromStorage)
		}

		let fromServer
		let retryFlag = false

		try {
			fromServer = await this._fetch(lng)
		} catch (e) {
			retryFlag = typeof e == 'boolean' ? e : false
		}

		if (!fromServer) return cb(new Error('Failed to fetch definitions'), retryFlag)

		this._l.log(LOG_TAG, `Got definitions for '${lng}' from server`)

		cb(null, fromServer)

		this._writeStorage(lng, fromServer)
	}

	create() {
		/* Not implemented */
	}

	private async _fetch(lng: string): Promise<Maybe<ResourceLanguage>> {
		try {
			return await this._loadLang(lng)
		} catch {
			this._l.error(LOG_TAG, `Error parsing translations for '${lng}'`)
			throw false
		}
	}

	private _getStorage(): I18nStore {
		const rawStorage = getLocalStorage().getItem(STORAGE_KEY)

		if (rawStorage) {
			try {
				const i18nStore: Maybe<I18nStore> = JSON.parse(rawStorage)

				if (i18nStore) {
					this._l.log(LOG_TAG, 'Found i18n storage')
					return i18nStore
				}
			} catch {
				this._l.warn(LOG_TAG, 'Failed to parse i18n storage')
			}
		}

		this._l.log(LOG_TAG, 'Could not read i18n storage. Creating a fresh instance.')

		return {
			t: {},
			version: process.env.APP_VERSION,
		}
	}

	private _readStorage(lng: string): Maybe<ResourceLanguage> {
		// NOTE: We can uncomment below if working on new translations, and we need
		//  to get live updates.
		// if (this.i18nOpts.debug) return null
		const { t, version } = this._getStorage()

		if (version !== process.env.APP_VERSION) {
			this._requiresUpgrade = true
			this._l.log(
				LOG_TAG,
				`Stored version: ${version} doesn't match app version: ${process.env.APP_VERSION}. Clearing.`,
			)
			return null
		}

		return t[lng]
	}

	private _writeStorage(lng: string, translations: ResourceLanguage) {
		const i18nStore = this._getStorage()

		// Storage.setItem can throw so we wrap it.
		try {
			// If we need to clear the translations cache, we start with a new empty
			// object. Otherwise, we're probably just caching translations for a new
			// language, so we extend what we have.
			const currentTranslationsObj = this._requiresUpgrade ? {} : i18nStore.t || {}
			const currentLngTranslations = currentTranslationsObj[lng] || {}

			const newLngStore: ResourceLanguage = Object.assign(currentLngTranslations, translations)
			const newTranslations: Resource = Object.assign(currentTranslationsObj, {
				[lng]: newLngStore,
			})

			// If we're upgrading to new translations, use the new model version.
			const newRawStorage: I18nStore = {
				t: newTranslations,
				version: this._requiresUpgrade ? process.env.APP_VERSION : i18nStore.version,
			}

			getLocalStorage().setItem(STORAGE_KEY, JSON.stringify(newRawStorage))

			this._requiresUpgrade = false
			this._l.log(LOG_TAG, `Cached translations for '${lng}'.`)
		} catch {
			this._l.error(LOG_TAG, `Error caching translations for '${lng}'.`)
		}
	}

	private get _l(): LoggerModule {
		return this.services.logger
	}

	//eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
	private _loadLang = (lng: Lang | string) => {
		switch (lng) {
			case 'de':
				return import('@op/i18n/ourpact-web_de.json')

			case 'es':
				return import('@op/i18n/ourpact-web_es.json')

			case 'fr':
				return import('@op/i18n/ourpact-web_fr.json')

			case 'nl':
				return import('@op/i18n/ourpact-web_nl.json')

			case 'pt-br':
				return import('@op/i18n/ourpact-web_pt-br.json')

			case 'zh-cn':
				return import('@op/i18n/ourpact-web_zh-cn.json')

			case 'zh-tw':
				return import('@op/i18n/ourpact-web_zh-tw.json')

			case 'en':
			default:
				return import('@op/i18n/ourpact-web_en.json')
		}
	}
}
