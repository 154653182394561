import { DEFAULT_AVATAR_COLOR_TUPLE } from '@op/app-shared'
import AVATAR_1 from '@op/assets/robots/1.svg'
import AVATAR_2 from '@op/assets/robots/2.svg'
import AVATAR_3 from '@op/assets/robots/3.svg'
import AVATAR_4 from '@op/assets/robots/4.svg'
import type { AnyAvatar } from '@op/services'
import { activeChildId$, children$, isAvatar, isDefaultAvatar } from '@op/services'
import { concatLgStr } from '@op/util'
import { createSelector } from '@reduxjs/toolkit'

const DEFAULT_AVATAR_IMAGE_TUPLE = [AVATAR_1, AVATAR_2, AVATAR_3, AVATAR_4]

export const getAvatarCSSStyle = (
	avatar: AnyAvatar,
	colorIdx?: number,
): Pick<CSSStyleDeclaration, 'backgroundColor' | 'backgroundImage'> => {
	const backgroundColor =
		isDefaultAvatar(avatar) ?
			DEFAULT_AVATAR_COLOR_TUPLE[colorIdx ?? avatar.defaultColorIdx]
		:	'transparent'

	const backgroundImage =
		isAvatar(avatar) ?
			concatLgStr('url(', avatar.image, ')')
		:	`url("${DEFAULT_AVATAR_IMAGE_TUPLE[avatar.defaultImgIdx]}")`

	return {
		backgroundColor,
		backgroundImage,
	}
}

export type ChildAvatarColorMap = {
	readonly [userId: string]: string
}

export const childAvatarColorMap$ = /*@__PURE__*/ createSelector(children$, (c) =>
	c.reduce((map: Writable<ChildAvatarColorMap>, child) => {
		map[child.user_id] = DEFAULT_AVATAR_COLOR_TUPLE[child.settings.slot]

		return map
	}, {}),
)

export const activeChildAvatarColor$ = /*@__PURE__*/ createSelector(
	activeChildId$,
	childAvatarColorMap$,
	(activeChildId, childColorMap) =>
		activeChildId ? childColorMap[activeChildId] : DEFAULT_AVATAR_COLOR_TUPLE[0],
)
