import type { LocationData, ManagedDevice, ManagedUser } from '@op/services'

export const PICK_AVATAR_MARKER_DEVICE = [
	'device_id',
	'displayName',
] satisfies (keyof ManagedDevice)[]
export type AvatarMarkerDevice = Pick<ManagedDevice, (typeof PICK_AVATAR_MARKER_DEVICE)[number]>

export const PICK_GMAPS_USER = [
	'avatar',
	'img_modified',
	'devices',
	'user_id',
	'user_location_active',
	'user_name',
] satisfies (keyof ManagedUser)[]

export type AvatarMarkerUser = Pick<ManagedUser, (typeof PICK_GMAPS_USER)[number]>

export type AvatarMarkerData = {
	readonly device: AvatarMarkerDevice
	readonly location: LocationData
	readonly user: AvatarMarkerUser
}
